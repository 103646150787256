import React, { useEffect, useRef, useState } from "react";
import "./BacktestingResultTable.css";
import {
  AccuracyData,
  BackTestingResultsTableProps,
} from "../../../store/foreCastBackTestingDataSlice";
import {
  calculateDynamicCellPadding,
  renderTableDynamicRow,
} from "../../../utils/functions";
import { STATSTIC_FORECAST_PADDING_ADJUST_VALUE } from "../../../utils/constant";
const uniqueKey = require("unique-key");
interface BackTestingRowData {
  rowData: string[];
  dataType: string;
  weekData: AccuracyData[];
}

interface ResultVisulizationBackTestingRowData {
  rowData: string[];
  dataType: string;
}

const BacktestingResultTable: React.FC<BackTestingResultsTableProps> = ({
  tableHeader,
  chartData,
  backTestingTableData,
  isResultVisulizationPage,
  resultVisulizationTableData,
  isExperimentationPage,
}) => {
  const backTestingTableRowRef = useRef<any>(null);
  const [tableCellWidth, setTableCellWidth] = useState(0);
  const [tableWeekCellWidth, setTableWeekCellWidth] = useState(0);
  const [isTableSpanUpdated, setTableSpanUpdared] = useState(false);

  useEffect(() => {
    setTableCellWidth(
      calculateDynamicCellPadding(
        backTestingTableRowRef,
        tableHeader,
        isExperimentationPage ? true : false,
        false,
        isResultVisulizationPage
      ).calculatedRightPadding
    );
    setTableWeekCellWidth(
      calculateDynamicCellPadding(
        backTestingTableRowRef,
        tableHeader,
        isExperimentationPage ? true : false,
        false,
        isResultVisulizationPage
      ).calculateWeekColumnWidth
    );
    setTimeout(() => {
      setTableSpanUpdared(true);
    }, 1000);
  }, [backTestingTableRowRef]);

  /**
   * Generate a finaly array to render inside table row from api response
   * @returns
   */
  const renderBackTestingTableData = () => {
    let tableData: BackTestingRowData[] = [];
    let dynamicHeaders: string[] = [];
    let isDataTypeFound = false;
    let weekCount = 0;
    let shouldWeekCounterStart = false;

    tableHeader?.forEach((item) => {
      if (item !== "DATA TYPE" && isDataTypeFound === false) {
        dynamicHeaders.push(item);
      } else if (item === "DATA TYPE") {
        isDataTypeFound = true;
        shouldWeekCounterStart = true;
      }
      if (shouldWeekCounterStart) {
        weekCount++;
      }
    });
    if (isExperimentationPage) {
      backTestingTableData?.forEach((row, index) => {
        if (row?.ltp?.forecastAccuracyProduction !== undefined) {
          tableData.push({
            rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
            weekData: [...row?.ltp?.forecastAccuracyProduction],
            dataType: "Forecast Accuracy Production",
          });
        }
        if (row?.ltp?.forecastAccuracyExperimentation !== undefined) {
          const isForecastRank1Present =
            row?.ltp?.forecastAccuracyExperimentation?.some((item) => {
              if (item?.rank === 1) {
                return true;
              }
              return false;
            });

          const isForecastRank2Present =
            row?.ltp?.forecastAccuracyExperimentation?.some((item) => {
              if (item?.rank === 2) {
                return true;
              }
              return false;
            });
          const isForecastRank3Present =
            row?.ltp?.forecastAccuracyExperimentation?.some((item) => {
              if (item?.rank === 3) {
                return true;
              }
              return false;
            });
          if (isForecastRank1Present) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [
                ...row?.ltp?.forecastAccuracyExperimentation?.filter(
                  (item) => item?.rank === 1
                ),
              ],
              dataType: `Forecast Accuracy Rank 1`,
            });
          }
          if (isForecastRank2Present) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [
                ...row?.ltp?.forecastAccuracyExperimentation?.filter(
                  (item) => item?.rank === 2
                ),
              ],
              dataType: `Forecast Accuracy Rank 2`,
            });
          }
          if (isForecastRank3Present) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [
                ...row?.ltp?.forecastAccuracyExperimentation?.filter(
                  (item) => item?.rank === 3
                ),
              ],
              dataType: `Forecast Accuracy Rank 3`,
            });
          }
        }
        if (row?.ltp?.biasProduction !== undefined) {
          tableData.push({
            rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
            weekData: [...row?.ltp?.biasProduction],
            dataType: "Bias Production",
          });
        }

        if (row?.ltp?.biasExperimentation !== undefined) {
          const isBiasRank1Present = row?.ltp?.biasExperimentation?.some(
            (item) => {
              if (item?.rank === 1) {
                return true;
              }
              return false;
            }
          );

          const isBiasRank2Present = row?.ltp?.biasExperimentation?.some(
            (item) => {
              if (item?.rank === 2) {
                return true;
              }
              return false;
            }
          );
          const isBiasRank3Present = row?.ltp?.biasExperimentation?.some(
            (item) => {
              if (item?.rank === 3) {
                return true;
              }
              return false;
            }
          );

          if (isBiasRank1Present) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [
                ...row?.ltp?.biasExperimentation?.filter(
                  (item) => item?.rank === 1
                ),
              ],
              dataType: "Bias Rank 1",
            });
          }
          if (isBiasRank2Present) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [
                ...row?.ltp?.biasExperimentation?.filter(
                  (item) => item?.rank === 2
                ),
              ],
              dataType: `Bias Rank 2`,
            });
          }

          if (isBiasRank3Present) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [
                ...row?.ltp?.biasExperimentation?.filter(
                  (item) => item?.rank === 3
                ),
              ],
              dataType: `Bias Rank 3`,
            });
          }
        }
      });
    } else {
      backTestingTableData?.forEach((row, index) => {
        tableData.push({
          rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
          weekData: [...row?.ltp?.forecastAccuracy],
          dataType: "Forecast Accuracy",
        });

        row?.ltp?.bias?.forEach((dataForeast, dataBiasIndex) => {
          if (dataBiasIndex === 0) {
            tableData.push({
              rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
              weekData: [...row?.ltp?.bias],
              dataType: "Bias",
            });
          }
        });
        if (!isResultVisulizationPage) {
          row?.ltp?.statisticalForecast?.forEach(
            (dataForeast, dataForecastIndex) => {
              if (dataForecastIndex === 0) {
                tableData.push({
                  rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
                  weekData: [...row?.ltp?.statisticalForecast],
                  dataType: "Statistical Forecast",
                });
              }
            }
          );

          row?.ltp?.historicalShipments?.forEach(
            (dataForeast, dataForcastIndex) => {
              if (dataForcastIndex === 0) {
                tableData.push({
                  rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
                  weekData: [...row?.ltp?.historicalShipments],
                  dataType: "Historical Shipments",
                });
              }
            }
          );
        }
      });
    }

    return isExperimentationPage
      ? renderExperimentBackTestingTableHtmlTableData(tableData, weekCount)
      : renderHtmlTableData(tableData, weekCount);
  };

  /**
   * Render the sub array week data from the parent array for backtesting
   * @param weekDataArray
   * @returns ReactElement
   */
  const getWeekData = (
    weekDataArray: AccuracyData[],
    dataType: string,
    weekCount: number
  ): React.ReactElement[] => {
    const weekDataDifference = weekCount - weekDataArray?.length;
    for (let i = 0; i < weekDataDifference - 1; i++) {
      weekDataArray.push({
        weekNumber: "",
        value: 0,
      });
    }
    return weekDataArray?.map((item: AccuracyData, index: any) => {
      let itemData = "";
      if (
        (dataType === "Forecast Accuracy" || "Bias") &&
        dataType !== "Statistical Forecast" &&
        dataType !== "Historical Shipments"
      ) {
        itemData = `${item?.value}%`;
      } else {
        itemData = `${item?.value}`;
      }
      if (item?.weekNumber === "") {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          ></td>
        );
      } else {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          >
            {itemData}
          </td>
        );
      }
    });
  };

  /**
   * Render the sub array week data from the parent array for backtesting
   * @param weekDataArray
   * @returns ReactElement
   */
  const getWeekDataForExperiment = (
    weekDataArray: AccuracyData[],
    weekCount: number
  ): React.ReactElement[] => {
    const weekDataDifference = weekCount - weekDataArray?.length;
    for (let i = 0; i < weekDataDifference - 1; i++) {
      weekDataArray.push({
        weekNumber: "",
        periodWeekNumber: "",
        value: 0,
      });
    }
    return weekDataArray?.map((item: AccuracyData, index: any) => {
      if (item?.periodWeekNumber === "") {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          ></td>
        );
      } else {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4"
          >
            {item?.value}%
          </td>
        );
      }
    });
  };

  const renderHtmlTableData = (
    tableData: BackTestingRowData[],
    weekCount: number
  ) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: BackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg backtesting-table-row-data"
          ref={backTestingTableRowRef}
        >
          {data?.dataType === "Forecast Accuracy"
            ? data?.rowData?.map((item) => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border p-4"
                  >
                    {item}
                  </td>
                );
              })
            : data?.rowData?.map(() => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata-empty")}`}
                    className="border border-primary-border p-4"
                  ></td>
                );
              })}
          <td className="backtestingResultDataRow border border-primary-border p-4">
            {data?.dataType}
          </td>

          {getWeekData(data?.weekData, data?.dataType, weekCount)}
        </tr>
      ));
    }
  };

  const renderExperimentBackTestingTableHtmlTableData = (
    tableData: BackTestingRowData[],
    weekCount: number
  ) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: BackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
          ref={backTestingTableRowRef}
        >
          {data?.dataType === "Forecast Accuracy Production"
            ? data?.rowData?.map((item) => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border p-4"
                  >
                    {item}
                  </td>
                );
              })
            : data?.rowData?.map(() => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata-empty")}`}
                    className="border border-primary-border p-4"
                  ></td>
                );
              })}
          <td className="backtestingResultDataRow border border-primary-border p-4">
            {data?.dataType}
          </td>

          {getWeekDataForExperiment(data?.weekData, weekCount)}
        </tr>
      ));
    }
  };

  const renderExperiemtnResultVisulizationHtmlTableData = (
    tableData: ResultVisulizationBackTestingRowData[],
    dataType: string,
    experimentLableText: string,
    isProductionRunEmpty: boolean
  ) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: ResultVisulizationBackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          {data?.dataType === dataType ? (
            <>
              <td
                className="border border-primary-border bg-zinc-700 p-4"
                style={{ paddingRight: `${tableCellWidth}px` }}
              >
                <div className="flex items-center">
                  <span className="pl-2">{dataType}</span>
                </div>
              </td>

              <td
                key={`${uniqueKey("key_backtesting_rowdata")}`}
                className="border border-primary-border bg-zinc-700 p-4"
                style={{ paddingRight: `110px`}}
              >
                {experimentLableText}
              </td>
              {data?.rowData?.map((item) => {
                return (
                  <>
                    <td
                      key={`${uniqueKey("key_backtesting_rowdata")}`}
                      className="border border-primary-border bg-zinc-700 p-4"
                      style={{ paddingRight: `${tableWeekCellWidth}px`}}
                    >
                      {item}
                    </td>
                  </>
                );
              })}
            </>
          ) : (
            data?.rowData?.map((item, index) => {
              return (
                <>
                  {index === 0 && (
                    <>
                      {isProductionRunEmpty ? (
                        <td
                          className="border border-primary-border bg-zinc-700 p-4"
                          style={{ paddingRight: `${tableCellWidth}px` }}
                        >
                          <div className="flex items-center">
                            <span className="pl-2">{dataType}</span>
                          </div>
                        </td>
                      ) : (
                        <td
                          key={`${uniqueKey("key_backtesting_rowdata")}`}
                          className="border border-primary-border bg-zinc-700 p-4"
                        ></td>
                      )}
                      <td
                        key={`${uniqueKey("key_backtesting_rowdata")}`}
                        className="border border-primary-border bg-zinc-700 p-4"
                      >
                        {experimentLableText}
                      </td>
                    </>
                  )}
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border bg-zinc-700 p-4"
                  >
                    {item}
                  </td>
                </>
              );
            })
          )}
        </tr>
      ));
    }
  };

  const renderResultVisulizationHtmlTableData = (
    tableData: ResultVisulizationBackTestingRowData[],
    dataType: string
  ) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={tableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: ResultVisulizationBackTestingRowData) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          {data?.dataType === dataType
            ? data?.rowData?.map((item, index: number) => {
                return (
                  <>
                    {index === 0 && (
                      <td
                        className="border border-primary-border bg-zinc-700 p-4"
                        style={{
                          paddingRight: `${
                            tableCellWidth -
                            STATSTIC_FORECAST_PADDING_ADJUST_VALUE
                          }px`,
                        }}
                      >
                        <div className="flex items-center">
                          <span className="pl-2">{dataType}</span>
                        </div>
                      </td>
                    )}

                    <td
                      key={`${uniqueKey("key_backtesting_rowdata")}`}
                      className="border border-primary-border bg-zinc-700 p-4"
                      style={{ paddingRight: `${tableWeekCellWidth}px`}}
                    >
                      {item}
                    </td>
                  </>
                );
              })
            : data?.rowData?.map((item, index) => {
                return (
                  <>
                    {index === 0 && (
                      <td className="border border-primary-border  bg-zinc-700 p-4"></td>
                    )}

                    <td
                      key={`${uniqueKey("key_backtesting_rowdata")}`}
                      className="border border-primary-border bg-zinc-700 p-4"
                      style={{ paddingRight: `${tableWeekCellWidth}px`}}
                    >
                      {item}
                    </td>
                  </>
                );
              })}
        </tr>
      ));
    }
  };

  const renderChartDataTable = () => {
    const forecastAccuracy: any[] = [];
    const bias: any[] = [];
    const historicalShipment: any[] = [];
    const statisticalForecast: any[] = [];
    if (isResultVisulizationPage) {
      return renderResultVisulizationChartDataTable();
    } else if (isExperimentationPage) {
      return renderResultVisulizationExperimentChartDataTable();
    } else {
      chartData?.forEach((data) => {
        forecastAccuracy.push(data.accuracy);
        bias.push(data.bias);
        historicalShipment.push(data.historicalShipment);
        statisticalForecast.push(data.statisticalForecast);
      });
      if (
        forecastAccuracy?.length === 0 &&
        bias?.length === 0 &&
        historicalShipment?.length === 0 &&
        statisticalForecast?.length === 0
      ) {
        return null;
      }
      return (
        <table className="text-xs font-bold">
          <tbody>
            {forecastAccuracy?.length > 0 && (
              <tr className="btr-accurey-table-tr">
                <td
                  className="border border-primary-border p-4"
                  style={{
                    paddingRight: `${tableCellWidth}px`,
                  }}
                >
                  <div className="flex items-center">
                    <span className="pl-2">Forecast Accuracy</span>
                  </div>
                </td>
                {forecastAccuracy?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey("key_")}-forecast-backtesting-accuracy`}
                      className="backtestingResultDataRow border border-primary-border p-4"
                      style={{ paddingRight: `${tableWeekCellWidth}px`}}
                    >
                      {data}%
                    </td>
                  );
                })}
              </tr>
            )}
            {bias?.length > 0 && (
              <tr className="btr-accurey-table-tr ">
                <td className="border border-primary-border p-4 w-2/3">
                  <div className="flex w-2/3 items-center">
                    <span className="pl-2">Bias</span>
                  </div>
                </td>
                {bias?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey("key_")}-forecast-backtesting-bias`}
                      className="backtestingResultDataRow border border-primary-border p-4"
                      style={{ paddingRight: `${tableWeekCellWidth}px`}}
                    >
                      {data}%
                    </td>
                  );
                })}
              </tr>
            )}
            {statisticalForecast?.length > 0 && (
              <tr className="btr-accurey-table-tr ">
                <td className="border border-primary-border p-4 w-2/3">
                  <div className="flex w-2/3 items-center">
                    <span className="pl-2"> Statistical Forecast</span>
                  </div>
                </td>
                {statisticalForecast?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey(
                        "key_"
                      )}-forecast-backtesting-statistical-forecast`}
                      className="backtestingResultDataRow border border-primary-border p-4"
                      style={{ paddingRight: `${tableWeekCellWidth}px`}}
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            )}
            {historicalShipment?.length > 0 && (
              <tr className="btr-accurey-table-tr ">
                <td className="border border-primary-border p-4 w-2/3">
                  <div className="flex w-2/3 items-center">
                    <span className="pl-2"> Historical Shipment</span>
                  </div>
                </td>
                {historicalShipment?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey(
                        "key_"
                      )}-forecast-backtesting-historical-shipment`}
                      className="backtestingResultDataRow border border-primary-border p-4"
                      style={{ paddingRight: `${tableWeekCellWidth}px`}}
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        </table>
      );
    }
  };

  const renderResultVisulizationChartDataTable = () => {
    const forecastAccuracy: ResultVisulizationBackTestingRowData[] = [];
    const bias: ResultVisulizationBackTestingRowData[] = [];
    let isChartDataEmpty = resultVisulizationTableData?.every(
      (item) => item?.data?.length === 0
    );
    if (isChartDataEmpty) {
      return null;
    }
    resultVisulizationTableData?.forEach((item, index) => {
      const rankString = index === 0 ? "Top Rank" : `Rank ${item.rank}`;
      if (index === 0) {
        forecastAccuracy.push({
          rowData: [
            rankString,
            ...item?.data?.map((item: any) => `${item.accuracy}%`),
          ],
          dataType: "Forecast Accuracy",
        });
        bias.push({
          rowData: [
            rankString,
            ...item?.data?.map((item: any) => `${item.bias}%`),
          ],
          dataType: "Bias",
        });
      } else {
        forecastAccuracy.push({
          rowData: [
            rankString,
            ...item?.data?.map((item: any) => `${item.accuracy}%`),
          ],
          dataType: rankString,
        });
        bias.push({
          rowData: [
            rankString,
            ...item?.data?.map((item: any) => `${item.bias}%`),
          ],
          dataType: rankString,
        });
      }
    });
    if (forecastAccuracy?.length === 0 && bias?.length === 0) {
      return null;
    }

    return (
      <table className="text-xs font-bold">
        <tbody>
          {renderResultVisulizationHtmlTableData(
            forecastAccuracy,
            "Forecast Accuracy"
          )}
          {renderResultVisulizationHtmlTableData(bias, "Bias")}
        </tbody>
      </table>
    );
  };

  const renderResultVisulizationExperimentChartDataTable = () => {
    const forecastAccuracy: {
      productionRun: ResultVisulizationBackTestingRowData;
      rank1: ResultVisulizationBackTestingRowData;
      rank2: ResultVisulizationBackTestingRowData;
      rank3: ResultVisulizationBackTestingRowData;
    } = {
      productionRun: {
        dataType: "",
        rowData: [],
      },
      rank1: {
        dataType: "",
        rowData: [],
      },
      rank2: {
        dataType: "",
        rowData: [],
      },
      rank3: {
        dataType: "",
        rowData: [],
      },
    };

    const bias: {
      productionRun: ResultVisulizationBackTestingRowData;
      rank1: ResultVisulizationBackTestingRowData;
      rank2: ResultVisulizationBackTestingRowData;
      rank3: ResultVisulizationBackTestingRowData;
    } = {
      productionRun: {
        dataType: "",
        rowData: [],
      },
      rank1: {
        dataType: "",
        rowData: [],
      },
      rank2: {
        dataType: "",
        rowData: [],
      },
      rank3: {
        dataType: "",
        rowData: [],
      },
    };
    const statisticalForecast: {
      productionRun: ResultVisulizationBackTestingRowData;
      rank1: ResultVisulizationBackTestingRowData;
      rank2: ResultVisulizationBackTestingRowData;
      rank3: ResultVisulizationBackTestingRowData;
    } = {
      productionRun: {
        dataType: "",
        rowData: [],
      },
      rank1: {
        dataType: "",
        rowData: [],
      },
      rank2: {
        dataType: "",
        rowData: [],
      },
      rank3: {
        dataType: "",
        rowData: [],
      },
    };
    const historicalShipment: {
      productionRun: ResultVisulizationBackTestingRowData;
      rank1: ResultVisulizationBackTestingRowData;
      rank2: ResultVisulizationBackTestingRowData;
      rank3: ResultVisulizationBackTestingRowData;
    } = {
      productionRun: {
        dataType: "",
        rowData: [],
      },
      rank1: {
        dataType: "",
        rowData: [],
      },
      rank2: {
        dataType: "",
        rowData: [],
      },
      rank3: {
        dataType: "",
        rowData: [],
      },
    };

    resultVisulizationTableData?.forEach((item, index) => {
      if (item?.experimentId === "BTExperiment 2") {
        if (item.rank === 1) {
          forecastAccuracy.rank1.dataType = "Rank 1";
          forecastAccuracy?.rank1.rowData.push(`${item.accuracy}%`);
          bias.rank1.dataType = "Rank 1";
          bias?.rank1.rowData.push(`${item.bias}%`);
          statisticalForecast.rank1.dataType = "Rank 1";
          statisticalForecast?.rank1.rowData.push(
            `${item.statisticalForecast}`
          );
          historicalShipment.rank1.dataType = "Rank 1";
          historicalShipment?.rank1.rowData.push(`${item.historicalShipment}`);
        }
        if (item.rank === 2) {
          forecastAccuracy.rank2.dataType = "Rank 2";
          forecastAccuracy?.rank2.rowData.push(`${item.accuracy}%`);
          bias.rank2.dataType = "Rank 2";
          bias?.rank2.rowData.push(`${item.bias}%`);
          statisticalForecast.rank2.dataType = "Rank 2";
          statisticalForecast?.rank2.rowData.push(
            `${item.statisticalForecast}`
          );
          historicalShipment.rank2.dataType = "Rank 2";
          historicalShipment?.rank2.rowData.push(`${item.historicalShipment}`);
        }
        if (item.rank === 3) {
          forecastAccuracy.rank3.dataType = "Rank 3";
          forecastAccuracy?.rank3.rowData.push(`${item.accuracy}%`);
          bias.rank3.dataType = "Rank 3";
          bias?.rank3.rowData.push(`${item.bias}%`);
          statisticalForecast.rank3.dataType = "Rank 3";
          statisticalForecast?.rank3.rowData.push(
            `${item.statisticalForecast}`
          );
          historicalShipment.rank3.dataType = "Rank 3";
          historicalShipment?.rank3.rowData.push(`${item.historicalShipment}`);
        }
      } else {
        forecastAccuracy.productionRun.dataType = "Forecast Accuracy";
        forecastAccuracy?.productionRun?.rowData.push(`${item.accuracy}%`);
        bias.productionRun.dataType = "Bias";
        bias?.productionRun?.rowData.push(`${item.bias}%`);
        statisticalForecast.productionRun.dataType = "Statistical Forecast";
        statisticalForecast?.productionRun.rowData.push(
          `${item.statisticalForecast}`
        );
        historicalShipment.productionRun.dataType = "Historical Shipment";
        historicalShipment?.productionRun.rowData.push(
          `${item.historicalShipment}`
        );
      }
    });

    return (
      <table className="text-xs font-bold">
        <tbody>
          {renderExperiemtnResultVisulizationHtmlTableData(
            [forecastAccuracy?.productionRun],
            "Forecast Accuracy",
            "Production Run",
            true
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [forecastAccuracy?.rank1],
            "Forecast Accuracy",
            "Rank 1",
            forecastAccuracy?.productionRun?.rowData?.length === 0
              ? true
              : false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [forecastAccuracy?.rank2],
            "Forecast Accuracy",
            "Rank 2",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [forecastAccuracy?.rank3],
            "Forecast Accuracy",
            "Rank 3",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [bias?.productionRun],
            "Bias",
            "Production Run",
            true
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [bias?.rank1],
            "Bias",
            "Rank 1",
            bias?.productionRun?.rowData?.length === 0 ? true : false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [bias?.rank2],
            "Bias",
            "Rank 2",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [bias?.rank3],
            "Bias",
            "Rank 3",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [statisticalForecast?.productionRun],
            "Statistical Forecast",
            "Production Run",
            true
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [statisticalForecast?.rank1],
            "Statistical Forecast",
            "Rank 1",
            statisticalForecast?.productionRun?.rowData?.length === 0
              ? true
              : false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [statisticalForecast?.rank2],
            "Statistical Forecast",
            "Rank 2",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [statisticalForecast?.rank3],
            "Statistical Forecast",
            "Rank 3",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [historicalShipment?.productionRun],
            "Historical Shipment",
            "Production Run",
            true
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [historicalShipment?.rank1],
            "Historical Shipment",
            "Rank 1",
            historicalShipment?.productionRun?.rowData?.length === 0
              ? true
              : false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [historicalShipment?.rank2],
            "Historical Shipment",
            "Rank 2",
            false
          )}
          {renderExperiemtnResultVisulizationHtmlTableData(
            [historicalShipment?.rank3],
            "Historical Shipment",
            "Rank 3",
            false
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className="p-6 mt-4 text-white table-holder-main">
      <div className="btr-table-holder">
        {isTableSpanUpdated && renderChartDataTable()}
        {tableHeader?.length > 0 ? (
          <table className="text-xs font-bold table-auto">
            <tbody>
              <tr className="bg-ternary-bg">
                {tableHeader?.map((data) => {
                  return (
                    <td
                      key={`${uniqueKey("key_")}-forecast-backtesting-headers`}
                      className="backtestingResultDataRow border border-primary-border p-4 "
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
              {renderBackTestingTableData()}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default BacktestingResultTable;
