import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";

type ChartSeries = Array<{
  name: string;
  type: string;
  color?: string;
  group?: string;
  zIndex?: number;
  data: number[] | { x: any; y: any }[];
}>;

interface BarStackedChartProps {
  series: ChartSeries;
  categories: string[];
}

const BarStackedChart: React.FC<BarStackedChartProps> = ({
  series,
  categories,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const strokeWidth = series.map((s) => (s.type === "line" ? 2 : 0));

      const options: ApexOptions = {
        series,
        chart: {
          height: 300,
          stacked: true,
          background: "#1D1D1D",
          toolbar: { show: true },
        },
        stroke: { width: strokeWidth },
        tooltip: {
          enabled: true,
          shared: true,
          followCursor: false,
          intersect: false,
          theme: "dark",
        },
        plotOptions: {
          bar: { horizontal: false },
        },
        dataLabels: { enabled: false },
        xaxis: {
          categories,
          labels: { style: { colors: "white" } },
        },
        yaxis: {
          labels: { style: { colors: "white" } },
          title: {
            text: "Volume",
            style: { color: "white", fontSize: "14px", fontWeight: "bold" },
          },
        },
        fill: { opacity: 1 },
        legend: {
          position: "top",
          horizontalAlign: "left",
          labels: { colors: "white" },
        },
        grid: { borderColor: "#616161" },
        theme: { mode: "dark" },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      return () => chart.destroy();
    }
  }, [series, categories]);

  return <div ref={chartRef} />;
};

export default BarStackedChart;
