import React, { useEffect, useRef, useState } from "react";
import "./ForecastAnalysisTable.css";
import { ForeCastApiChartType } from "../../../store/foreCastDataSlice";
import { AccuracyData } from "../../../store/foreCastBackTestingDataSlice";
import {
  calculateDynamicCellPadding,
  renderTableDynamicRow,
} from "../../../utils/functions";
const uniqueKey = require("unique-key");

interface ForecastAnalysisTableProps {
  tableHeaders: ForeCastApiChartType[];
  dynamicTableHeader: string[];
  forecastAnalysisData: {
    tableRowData: {
      keyAccount: string;
      salesRegion: string;
      clusterId: string;
      upc: string;
      productLineName: string;
      ltp: {
        statisticalForecast: {
          periodWeekNumber: string;
          timeFrameStartDate: string;
          value: number;
        }[];
        historicalShipments: {
          periodWeekNumber: string;
          timeFrameStartDate: string;
          value: number;
        }[];
      };
    }[];
  };
}

const ForecastTable: React.FC<{
  tableHeaders: ForeCastApiChartType[];
  padding: number;
  tableWeekCellWidth: number
}> = ({ tableHeaders, padding, tableWeekCellWidth }) => {
  return (
    <table className="text-xs font-bold">
      <tbody>
        <tr className="btr-accurey-table-tr">
          <td
            className="border border-primary-border p-4 text-white"
            style={{
              paddingRight: `${padding}px`,
            }}
          >
            <div className="flex items-center">
              <span className="pl-2"> Statistical Forecast </span>
            </div>
          </td>
          {tableHeaders?.map((header, index) => (
            <td
              key={`${uniqueKey("key_table_header")}`}
              className="border border-primary-border p-4 text-white"
              style={{ paddingRight: `${tableWeekCellWidth}px`}}
            >
              {Math.trunc(header?.statisticalForecast)}
            </td>
          ))}
        </tr>
        <tr className="btr-accurey-table-tr">
          <td
            className="border border-primary-border p-4 text-white"
            style={{
              paddingRight: `${padding}px`,
            }}
          >
            <div className="flex items-center">
              <span className="pl-2"> Historical Shipment</span>
            </div>
          </td>
          {tableHeaders?.map((header) => (
            <td
              key={`${uniqueKey("key_table_header-historical")}}`}
              className="border border-primary-border p-4 text-white"
              style={{ paddingRight: `${tableWeekCellWidth}px`}}
            >
              {Math.trunc(header.historicalShipment)}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

const ForecastAnalysisTable: React.FC<ForecastAnalysisTableProps> = ({
  tableHeaders,
  dynamicTableHeader,
  forecastAnalysisData,
}) => {
  const backTestingTableRowRef = useRef<any>(null);
  const [tableCellWidth, setTableCellWidth] = useState(0);
  const [tableWeekCellWidth, setTableWeekCellWidth] = useState(0);

  useEffect(() => {
    setTableCellWidth(
      calculateDynamicCellPadding(
        backTestingTableRowRef,
        dynamicTableHeader,
        false,
        true
      ).calculatedRightPadding
    );
    setTableWeekCellWidth(
      calculateDynamicCellPadding(
        backTestingTableRowRef,
        dynamicTableHeader,
        false,
        true
      ).calculateWeekColumnWidth
    );
  }, [backTestingTableRowRef]);

  const renderForecastAnalysisTableData = () => {
    let tableData: any[] = [];

    let dynamicHeaders: string[] = [];
    let isDataTypeFound = false;
    let weekCount = 0;
    let shouldWeekCounterStart = false;
    dynamicTableHeader?.forEach((item) => {
      if (item !== "DATA TYPE" && isDataTypeFound === false) {
        dynamicHeaders.push(item);
      } else if (item === "DATA TYPE") {
        isDataTypeFound = true;
        shouldWeekCounterStart = true;
      }
      if (shouldWeekCounterStart) {
        weekCount++;
      }
    });
    forecastAnalysisData?.tableRowData?.forEach((row, index) => {
      tableData.push({
        rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
        weekData: [...row?.ltp?.statisticalForecast],
        dataType: "Statistical Forecast",
      });

      row?.ltp?.historicalShipments?.forEach((dataForeast, dataBiasIndex) => {
        if (dataBiasIndex === 0) {
          tableData.push({
            rowData: [...renderTableDynamicRow(dynamicHeaders, row)],
            weekData: [...row?.ltp?.historicalShipments],
            dataType: "Historical Shipments",
          });
        }
      });
    });
    return renderHtmlTableData(tableData, weekCount);
  };

  const renderHtmlTableData = (tableData: any[], weekCount: number) => {
    if (tableData.length === 0) {
      return (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
        >
          <td
            key={`${uniqueKey("key_backtesting_rowdata")}`}
            className="border border-primary-border p-4 text-center"
            colSpan={dynamicTableHeader?.length}
          >
            No Record Found
          </td>
        </tr>
      );
    } else {
      return tableData?.map((data: any) => (
        <tr
          key={`${uniqueKey("key_")}-forecast-backtesing-data-table-data`}
          className="bg-ternary-bg"
          ref={backTestingTableRowRef}
        >
          {data?.dataType === "Statistical Forecast"
            ? data?.rowData?.map((item: any) => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata")}`}
                    className="border border-primary-border p-4 text-white"
                  >
                    {item}
                  </td>
                );
              })
            : data?.rowData?.map(() => {
                return (
                  <td
                    key={`${uniqueKey("key_backtesting_rowdata-empty")}`}
                    className="border border-primary-border p-4 text-white"
                  ></td>
                );
              })}
          <td className="backtestingResultDataRow border border-primary-border p-4 text-white">
            {data?.dataType}
          </td>

          {getWeekData(data?.weekData, data?.dataType, weekCount)}
        </tr>
      ));
    }
  };

  /**
   * Render the sub array week data from the parent array for backtesting
   * @param weekDataArray
   * @returns ReactElement
   */
  const getWeekData = (
    weekDataArray: AccuracyData[],
    dataType: string,
    weekCount: number
  ): React.ReactElement[] => {
    const weekDataDifference = weekCount - weekDataArray?.length;
    for (let i = 0; i < weekDataDifference - 6; i++) {
      weekDataArray.push({
        weekNumber: "",
        value: 0,
      });
    }
    return weekDataArray?.map((item: AccuracyData) => {
      if (item?.weekNumber === "") {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4 text-white"
          ></td>
        );
      } else {
        return (
          <td
            key={uniqueKey("key_")}
            className="backtestingResultDataRow border border-primary-border p-4 text-white"
          >
            {Math.trunc(item?.value)}
          </td>
        );
      }
    });
  };

  return (
    <div className="btr-table-holder">
      {<ForecastTable tableHeaders={tableHeaders} padding={tableCellWidth} tableWeekCellWidth={tableWeekCellWidth}/>}
      <table className="text-xs font-bold table-auto">
        <tbody>
          <tr className="bg-ternary-bg">
            {dynamicTableHeader?.map((data) => {
              return (
                <td
                  key={`${uniqueKey("key_")}-forecast-backtesting-headers`}
                  className="backtestingResultDataRow border border-primary-border p-4 text-white"
                >
                  {data}
                </td>
              );
            })}
          </tr>
          {renderForecastAnalysisTableData()}
        </tbody>
      </table>
    </div>
  );
};

export default ForecastAnalysisTable;
