import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

interface SeriesData {
  name: string;
  type: "column" | "line";
  data: number[];
  color: string;
  icon?: string;
}

interface LineColumnChartProps {
  seriesData?: SeriesData[];
  columnWidth?: string;
  labels?: string[];
}

const processSeriesData = (seriesData: SeriesData[], labels: string[]) => {
  return seriesData.map((series) => ({
    ...series,
    data:
      series.data.length > 0 ? [...series.data] : Array(labels.length).fill(0),
  }));
};

const createChartOptions = (
  processedSeriesData: SeriesData[],
  columnWidth: string,
  labels: string[]
) => {
  return {
    series:
      processedSeriesData.length > 0
        ? processedSeriesData
        : [{ name: "Default", data: [], type: "line", color: "#fff" }],
    chart: {
      height: 315, // Increased from 250 to 350
      type: "line",
      background: "#1D1D1D",
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ",",
            headerCategory: "Weeks",
            headerValue: "value",
          },
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: columnWidth,
        borderRadius: 3,
        borderRadiusApplication: "top",
      },
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      categories: labels.length > 0 ? labels : ["N/A"],
    },
    yaxis: [
      {
        seriesName: [
          "Forecast Accuracy Production",
          "Forecast Accuracy Rank 1",
          "Forecast Accuracy Rank 2",
          "Forecast Accuracy Rank 3",
        ],
        labels: {
          style: {
            colors: "white",
          },
          formatter: (value: number) => `${value}%`,
        },
        title: {
          text: "Percentage",
        },
        opposite: true,
      },
      {
        seriesName: [
          "Statistical Forecast Production",
          "Statistical Forecast Rank 1",
          "Statistical Forecast Rank 2",
          "Statistical Forecast Rank 3",
          "Historical Shipment (Adjusted)",
        ],
        title: {
          text: "Volume",
        },
      },
    ],
    theme: {
      mode: "dark",
    },
    grid: {
      borderColor: "#616161",
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      markers: {
        customHTML: () => "",
      },
      labels: {
        colors: "white",
      },
      offsetX: 0, // Adjust horizontal offset
      offsetY: 5, // Adjust vertical offset to reduce margin
      itemMargin: {
        horizontal: 5, // Reduce horizontal spacing between legend items
        vertical: 2,  // Reduce vertical spacing between legend rows
      },
      formatter: (seriesName: string, opts: any) => {
        const index = opts.seriesIndex;
        const series = processedSeriesData[index];
        const icon = series.icon
          ? `<img src="${series.icon}" alt="${seriesName}" style="width:16px; height:16px; margin-right:4px; vertical-align:middle;" />`
          : "";
        return `${icon}${seriesName}`;
      },
    },
    tooltip: {
      y: {
        formatter: (
          value: number,
          { seriesIndex, w }: { seriesIndex: number; w: any }
        ) => {
          const seriesYAxisIndex = w.config.series[seriesIndex].yAxisIndex;
          if (seriesYAxisIndex === 0) {
            return `${value}%`;
          }
          return value;
        },
      },
    },
  };
};


const LineColumnChartExp: React.FC<LineColumnChartProps> = ({
  seriesData = [],
  columnWidth = "50%",
  labels = [],
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const processedSeriesData = processSeriesData(seriesData, labels);
      const options = createChartOptions(
        processedSeriesData,
        columnWidth,
        labels
      );

      try {
        const chart = new ApexCharts(chartRef.current, options);
        chart.render();
        return () => {
          chart.destroy();
        };
      } catch (error) {
        console.error("Error initializing chart:", error);
      }
    }
  }, [seriesData, columnWidth, labels]);

  return <div className="pt-3" ref={chartRef}></div>;
};

export default LineColumnChartExp;
