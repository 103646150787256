import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

interface SeriesData {
  name: string;
  type: "column" | "line";
  data: number[];
  color: string;
  icon?: string;
}

interface LineColumnChartProps {
  seriesData?: SeriesData[];
  columnWidth?: string;
  labels?: string[];
  leftTitle?: string;
  rightTitle?: string;
}

const LineColumnChart: React.FC<LineColumnChartProps> = ({
  seriesData = [],
  columnWidth = "50%",
  labels = [],
  leftTitle = '',  
  rightTitle = '',  
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const calculateBias = (seriesData: SeriesData[]) => {
    const biasSeries = seriesData.filter((series) =>
      series.name.toLowerCase().includes("bias")
    );
    const biasValues = biasSeries.flatMap((series) => series.data);
    return {
      minBias: Math.min(...biasValues),
      maxBias: Math.max(...biasValues),
    };
  };

  const formatLabel = (value: number, leftTitle: string) => {
    if (value >= 0) {
      return leftTitle === "Volume" ? `${Math.trunc(value)}` : `${Math.trunc(value)}%`;
    }
    return "";
  };

  const getYAxisOptions = (
    rightTitle: string, 
    leftTitle: string, 
    minBias: number, 
    maxBias: number
  ) => [
    {
      min: rightTitle === "Bias" ? minBias : undefined,
      max: rightTitle === "Bias" ? maxBias : undefined,
      seriesName: [
        "Top Recipe Forecast Bias",
        "Rank 2 Bias",
        "Rank 3 Bias",
      ],
      labels: {
        style: {
          colors: "white",
        },
        formatter: (value: number) => `${value}%`,
      },
      title: {
        text: rightTitle ?? '',  // Ensures rightTitle is a string, falling back to empty string
        style: {
          color: "white",
        },
      },
      opposite: true,
    },
    {
      seriesName: [
        "Statistical Forecast",
        "Historical Shipments (Adjusted)",
      ],
      max: leftTitle === "Volume" ? undefined : 100,
      labels: {
        style: {
          colors: "white",
        },
        formatter: (value: number) => formatLabel(value, leftTitle),
      },
      title: {
        text: leftTitle ?? '',  // Ensures leftTitle is a string, falling back to empty string
        style: {
          color: "white",
        },
      },
    },
  ];

  const getLegendFormatter = (seriesData: SeriesData[]) => (seriesName: string, opts: any) => {
    const index = opts.seriesIndex;
    const series = seriesData[index];
    const icon = series.icon
      ? `<img src="${series.icon}" alt="${seriesName}" style="width:16px; height:16px; margin-right:4px; vertical-align:middle;" />`
      : "";
    return `${icon}${seriesName}`;
  };

  useEffect(() => {
    if (chartRef.current) {
      const { minBias, maxBias } = calculateBias(seriesData);
      const reversedSeriesData = seriesData.map((series) => ({
        ...series,
        data: [...series.data],
      }));

      const options = {
        series: reversedSeriesData,
        chart: {
          height: 250,
          type: "line",
          background: "#1D1D1D",
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ",",
                headerCategory: "Weeks",
                headerValue: "value",
              },
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: columnWidth,
            borderRadius: 3,
            borderRadiusApplication: "top",
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: labels,
        },
        yaxis: getYAxisOptions(rightTitle, leftTitle, minBias, maxBias),
        tooltip: {
          y: {
            formatter: (
              value: number,
              { seriesIndex, w }: { seriesIndex: number; w: any }
            ) => {
              const seriesYAxisIndex = w.config.series[seriesIndex].yAxisIndex;
              if (seriesYAxisIndex === 0) {
                return `${value}%`;
              }
              return value;
            },
          },
        },
        theme: {
          mode: "dark",
        },
        grid: {
          borderColor: "#616161",
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          markers: {
            customHTML: () => "", // Removes default markers completely
          },
          labels: {
            colors: "white",
          },
          formatter: getLegendFormatter(seriesData),
        },
      };

      try {
        const chart = new ApexCharts(chartRef.current, options);
        chart.render();

        return () => {
          chart.destroy();
        };
      } catch (error) {
        console.error("Error initializing chart:", error);
      }
    }
  }, [seriesData, columnWidth, labels, leftTitle, rightTitle]);

  return (
    <div className="pt-3 m-2">
      <div ref={chartRef}></div>
    </div>
  );
};

export default LineColumnChart;
