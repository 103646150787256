import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  setForecastFilter,
  setRcaFilter,
  setResultVisualizationFilter,
} from "../../../store/filterSlice";
import {
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

type InputTypeProps = {
  itemList?: string[];
  itemType: string;
  lag: boolean;
  autoSelectFirst?: boolean;
  disabled?: boolean;
};

export default function SnapshotDateDropdown({
  itemList = [],
  itemType,
  lag,
  autoSelectFirst,
  disabled,
}: Readonly<InputTypeProps>) {
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.forecastFilter);

  const theme = createTheme({
    palette: {
      background: {
        paper: "#272727",
      },
      text: {
        primary: "#fff",
      },
    },
  });

  React.useEffect(() => {
    if (autoSelectFirst && itemList.length > 0) {
      const firstItem = itemList[0];
      const itemKey = itemType as keyof typeof filter;
      dispatch(setForecastFilter({ key: itemKey, value: [firstItem] }));
      dispatch(setRcaFilter({ key: itemKey, value: [firstItem] }));
      dispatch(
        setResultVisualizationFilter({ key: itemKey, value: [firstItem] })
      );
    }
  }, [autoSelectFirst, itemList, itemType]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const valuez = [event?.target?.value] as string[];
    const itemKey = itemType as keyof typeof filter;
    dispatch(setForecastFilter({ key: itemKey, value: valuez }));
    dispatch(setRcaFilter({ key: itemKey, value: valuez }));
    dispatch(setResultVisualizationFilter({ key: itemKey, value: valuez }));
  };

  const selectedValue = filter[itemType as keyof typeof filter] || "";

  return (
    <div className="flex">
      <ThemeProvider theme={theme}>
        <FormControl
          sx={{
            width: "70%",
            "& .Mui-disabled": {
              color: "white !important",
            },
          }}
          disabled={disabled}
        >
          {disabled && autoSelectFirst ? (
            <Typography
              sx={{
                color: "white",
                padding: "8px",
                backgroundColor: "#272727",
                border: "1px solid #616161",
                borderRadius: "4px",
              }}
            >
              {selectedValue.length > 0 ? selectedValue : "No Date"}
            </Typography>
          ) : (
            <Select
              value={selectedValue}
              onChange={handleChange}
              renderValue={(selected) =>
                selected?.length === 0 ? (
                  <span style={{ color: "#909090" }}>Select</span>
                ) : (
                  selected
                )
              }
              displayEmpty
              sx={{
                height: "38px",
                "& .MuiInputBase-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              }}
            >
              {itemList?.map((item, index) => (
                <MenuItem key={item} value={item}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    checked={
                      filter[itemType as keyof typeof filter].length > 0
                        ? filter[itemType as keyof typeof filter].indexOf(
                            item
                          ) > -1
                        : index === 0 // Select the first item if no filter is set
                    }
                  />
                  <ListItemText primary={item} sx={{ color: "white" }} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        {lag && (
          <FormControl
            sx={{
              width: "30%",
              position: "relative",
              bottom: "28px",
              padding: "4px",
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: "#909090", fontSize: "13px" }}
            >
              Lag
            </Typography>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "10px",
                padding: "8px",
                border: "1px solid #616161",
                borderRadius: "4px",
                color: "white",
                backgroundColor: "#272727",
              }}
            >
              {filter?.term?.toString() === "ST" ? "4" : "6"}
            </Typography>
          </FormControl>
        )}
      </ThemeProvider>
    </div>
  );
}
