import React, { useEffect, useState } from "react";
import "./cardTime.css";
import { useOktaAuth } from "@okta/okta-react";
import { useMidTermData, useShortTermData } from "../../../api/filtersApi";
import { useDispatch } from "react-redux";
import {
  setForecastFilter,
  setRcaFilter,
  setResultVisualizationFilter,
} from "../../../store/filterSlice";
import SnapshotDateDropdown from "./SnapshotDateDropdown";
import loadingSvg from "../../../images/bouncing-circles.svg";
import { useLocation } from "react-router-dom";
import { findAndSortCurrentPathSnapshotDates } from "../../../utils/functions";

type TimeProp = {
  lag: boolean;
  label: string;
  isDisabled: boolean;
};

const TimeCardComponent: React.FC<TimeProp> = ({ lag, label, isDisabled }) => {
  const [active, setActive] = useState<"short" | "mid">("short");
  const { authState } = useOktaAuth();
  const { pathname } = useLocation();
  const currentPath =
    pathname?.split("/")?.[1] || "statisticalforecastanalysis";

  const dispatch = useDispatch();

  const handleToggle = (type: "short" | "mid") => {
    setActive(type);
  };

  const { data: shortTermApiData, isLoading: isShortTermLoading } =
    useShortTermData(authState?.accessToken?.accessToken!, "short");
  const { data: midTermApiData, isLoading: isMidTermLoading } = useMidTermData(
    authState?.accessToken?.accessToken!,
    "mid"
  );

  useEffect(() => {
    if (active === "short") {
      dispatch(setForecastFilter({ key: "term", value: ["ST"] }));
      dispatch(setRcaFilter({ key: "term", value: ["ST"] }));
      dispatch(setResultVisualizationFilter({ key: "term", value: ["ST"] }));
    } else if (active === "mid") {
      dispatch(setForecastFilter({ key: "term", value: ["MT"] }));
      dispatch(setRcaFilter({ key: "term", value: ["MT"] }));
      dispatch(setResultVisualizationFilter({ key: "term", value: ["MT"] }));
    }
  }, [active, dispatch]);

  return (
    <div className="p-2 bg-secondary-bg rounded h-64">
      <div className="flex justify-between">
        <h3 className="m-2 p-1 font-bold">Time</h3>
      </div>
      <div className="flex">
        <div className="toggle-container">
          <div className="toggle-label">Horizon</div>
          <div className="toggle-button">
            <button
              className={active === "short" ? "active" : ""}
              onClick={() => handleToggle("short")}
            >
              Short Term
            </button>
            <button
              className={active === "mid" ? "active" : ""}
              onClick={() => handleToggle("mid")}
            >
              Mid Term
            </button>
          </div>
        </div>

        <div className="date-input">
          <div className="toggle-label">{label}</div>
          {active === "short" ? (
            <div>
              {isShortTermLoading ? (
                <div className="flex justify-center">
                  <img
                    src={loadingSvg}
                    alt="loading"
                    className="mt-10"
                    width={40}
                    height={40}
                  />
                </div>
              ) : (
                <SnapshotDateDropdown
                  itemType="shortSnapshotDate"
                  autoSelectFirst={true}
                  disabled={isDisabled ? true : false}
                  itemList={findAndSortCurrentPathSnapshotDates(
                    shortTermApiData,
                    currentPath
                  )}
                  lag={lag}
                />
              )}
            </div>
          ) : (
            <div>
              {isMidTermLoading ? (
                <div className="flex justify-center">
                  <img
                    src={loadingSvg}
                    alt="loading"
                    className="mt-10"
                    width={40}
                    height={40}
                  />
                </div>
              ) : (
                <SnapshotDateDropdown
                  itemType="midSnapshotDate"
                  autoSelectFirst={true}
                  disabled={isDisabled ? true : false}
                  itemList={findAndSortCurrentPathSnapshotDates(
                    midTermApiData,
                    currentPath
                  )}
                  lag={lag}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeCardComponent;
