import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FilterState {
  term: string[];
  keyAccount: string[];
  category: string[];
  clusters: string[];
  productLine: string[];
  productGroup: string[];
  mg4PortfolioGroup: string[];
  brand: string[];
  subBrand: string[];
  container: string[];
  ppg: string[];
  size: string[];
  count: string[];
  planningItem: string[];
  global: string[];
  customerSector: string[];
  customerRegion: string[];
  customerGroup: string[];
  businessUnit: string[];
  marketUnit: string[];
  ibpDemandGroup: string[];
  cg1TrageSegment: string[];
  salesSector: string[];
  country: string[];
  salesRegion: string[];
  businessOrg: string[];
  division: string[];
  segment: string[];
  ac1ac2: string[];
  recipeRuns: string[];
  shortSnapshotDate: string[];
  midSnapshotDate: string[];
  experimentId: string[];
  lagNumber: string[];
  rankBackTestingChart: string[];
  rankForward: string[];
  rankComparision: string[];
  rankPredefined: string[];
}

const initialState: FilterState = {
  term: ["ST"],
  shortSnapshotDate: [],
  midSnapshotDate: [],
  clusters: [],
  keyAccount: [],
  category: [],
  productLine: [],
  productGroup: [],
  mg4PortfolioGroup: [],
  brand: [],
  subBrand: [],
  container: [],
  ppg: [],
  size: [],
  count: [],
  planningItem: [],
  global: [],
  customerSector: [],
  customerRegion: [],
  customerGroup: [],
  businessUnit: [],
  marketUnit: [],
  ibpDemandGroup: [],
  cg1TrageSegment: [],
  salesSector: [],
  country: [],
  salesRegion: [],
  businessOrg: [],
  division: [],
  segment: [],
  ac1ac2: [],
  recipeRuns: [],
  experimentId: [],
  lagNumber: [],
  rankBackTestingChart: [],
  rankForward: [],
  rankComparision: [],
  rankPredefined: [],
};

export const forecastFilterSlice = createSlice({
  name: "forecastFilter",
  initialState,
  reducers: {
    setForecastFilter: (
      state,
      action: PayloadAction<{
        key: keyof FilterState;
        value: string[];
      }>
    ) => {
      const { key, value } = action?.payload;
      state[key] = value;
    },
    resetForecastState: (
      state,
      action: PayloadAction<{ key: keyof FilterState; value: string[] }>
    ) => ({ ...initialState, [action?.payload.key]: action?.payload.value }),
  },
});

export const rcaFilterSlice = createSlice({
  name: "rcaFilter",
  initialState,
  reducers: {
    setRcaFilter: (
      state,
      action: PayloadAction<{
        key: keyof FilterState;
        value: string[];
      }>
    ) => {
      const { key, value } = action?.payload;
      state[key] = value;
    },
    resetRcaState: () => initialState,
  },
});

export const resultVisualizationFilter = createSlice({
  name: "resultVisualizationFilter",
  initialState,
  reducers: {
    setResultVisualizationFilter: (
      state,
      action: PayloadAction<{
        key: keyof FilterState;
        value: string[];
      }>
    ) => {
      const { key, value } = action?.payload;
      state[key] = value;
    },
    resetResultVisualizationState: () => initialState,
    borrowFiltersFromForecast: (state, action: PayloadAction<FilterState>) => {
      return { ...action?.payload };
    },
  },
});

export const { setForecastFilter, resetForecastState } =
  forecastFilterSlice.actions;
export const { setRcaFilter, resetRcaState } = rcaFilterSlice.actions;
export const {
  setResultVisualizationFilter,
  resetResultVisualizationState,
  borrowFiltersFromForecast,
} = resultVisualizationFilter.actions;
