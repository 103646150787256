import { Box, Modal } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackTestingAnalytics from "../../components/BackTesting/BackTestingAnalytics/BackTestingAnalytics";
import BacktestingResultTable from "../../components/BackTesting/BacktestingResultTable/BacktestingResultTable";
import LineColumnChart from "../../components/chart/LineColumn/LineColumnChart";
import { DataShownDialog } from "../../components/Datashow";
import arrowLogo from "../../images/arrow.svg";
import closeButton from "../../images/closeButton.svg";
import enlargeButton from "../../images/enlargeButton.svg";
import { borrowFiltersFromForecast } from "../../store/filterSlice";
import { RootState } from "../../store/store";
import loadingSvg from "../../images/bouncing-circles.svg";
import rectanglePink from "../../images/rectanglePinkLight.svg";
import rectangleYellow from "../../images/rectangleYello.svg";
import ovalPink from "../../images/ovalDarkPink.svg";
import settingsIcon from "../../images/Settings.svg";
import {
  BackTestingChartData,
  BacktestingResultsProps,
  ChartData,
  foreCastBackTestingTableDataSetter,
  updateBackTestingTotalPages,
  updateBackTestingPageNumber,
} from "../../store/foreCastBackTestingDataSlice";
import { Pagination } from "../../components/Pagination";
import {
  calculateTotalPages,
  forecastBackTestingDefaultGroupBy,
  forecastBackTestingTableApi,
  mapperForGroupByLableToKey,
} from "../../api/forecastPageApi";
import { useOktaAuth } from "@okta/okta-react";
import { FIRST_PAGE, PAGE_LIMIT } from "../../utils/constant";

function BacktestingResults({
  widthColumn,
  isResultVisulizationPage,
  isExperimentationPage,
}: BacktestingResultsProps) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { authState } = useOktaAuth();
  const [selectedDataShownOptionList, setSelectedDataShownOptionList] =
    useState<string[]>(forecastBackTestingDefaultGroupBy);
  const [selectedDataShownCategoryList, setSelectedDataShownCategoryList] =
    useState<string[]>([]);
  const foreCastBackTestingTableData = useSelector(
    (state: RootState) => state.foreCastBackTestingTableDataSlice
  );
  const foreCastBackTestingChartData = useSelector(
    (state: RootState) => state.foreCastBackTestingChartDataSlice
  );
  const foreCastBackTestingAverageData = useSelector(
    (state: RootState) => state.foreCastBackTestingAverageDataSlice
  );
  const foreCastBackTestingDataLoading = useSelector(
    (state: RootState) => state.foreCastBackTestingDataLoading
  );

  const resultVisualizationData = useSelector(
    (state: RootState) => state.resultVisualizationData
  );

  const [openSettings, setOpenSettings] = useState(false);
  const [backTesingChartData, setBackTestingChartData] =
    useState<BackTestingChartData>({
      backTestingSeriesData: [],
      backTestingChartLabels: [],
    });
  const navigate = useNavigate();
  const filter = useSelector((state: RootState) => state.forecastFilter);

  const backTestingPagination = useSelector(
    (state: RootState) => state.foreCastBackTestingPagination
  );

  useEffect(() => {
    if (foreCastBackTestingChartData?.chartData?.length > 0) {
      const forecastAccuracyChartData =
        foreCastBackTestingChartData?.chartData?.map(
          (data: ChartData) => data.accuracy
        );
      const statisticalForecastChartData =
        foreCastBackTestingChartData?.chartData?.map(
          (data: ChartData) => data.statisticalForecast
        );
      const historicalShipmentChartData =
        foreCastBackTestingChartData?.chartData?.map(
          (data: ChartData) => data.historicalShipment
        );
      setBackTestingChartData({
        backTestingSeriesData: [
          {
            name: "Statistical Forecast",
            type: "line",
            data: statisticalForecastChartData,
            color: "#B992EB",
            yAxisIndex: 1,
            icon: rectanglePink,
          },
          {
            name: "Historical Shipments (Adjusted)",
            type: "line",
            data: historicalShipmentChartData,
            color: "#FFA800",
            yAxisIndex: 1,
            icon: rectangleYellow,
          },
          {
            name: "Forecast Accuracy",
            type: "column",
            data: forecastAccuracyChartData,
            color: "#6E3BB2",
            yAxisIndex: 0,
            icon: ovalPink,
          },
        ],
        backTestingChartLabels:
          foreCastBackTestingChartData?.backTestingChartLabels,
      });
    } else {
      setBackTestingChartData({
        backTestingSeriesData: [],
        backTestingChartLabels: [],
      });
    }
  }, [foreCastBackTestingChartData]);

  function sortChartLabels(labels: string[]): string[] {
    return [...labels].sort((a, b) => {
      const dateA = new Date(a).getTime();
      const dateB = new Date(b).getTime();

      // Compare the timeFrameDate values in ascending order
      return dateA - dateB;
    });
  }

  const handleSettingModal = () => {
    mapperForGroupByLableToKeyHandler(selectedDataShownOptionList);
    setOpenSettings(true);
  };

  const applyFilterAndRedirect = () => {
    dispatch(borrowFiltersFromForecast(filter));
    return navigate("/resultvisualization", { replace: true });
  };

  const renderBackTestingAnalyticsHtml = () => {
    return (
      <div className="pl-3 pt-1">
        {foreCastBackTestingAverageData?.isAverageDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <div className="mb-7">
            {foreCastBackTestingAverageData.overallPercent.length > 0 ? (
              <BackTestingAnalytics
                overallAverage={foreCastBackTestingAverageData?.overallAverage}
                overallPercent={foreCastBackTestingAverageData?.overallPercent}
              />
            ) : (
              <p>aa</p>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderBackTestingChartHtml = (): ReactElement => {
    let chartComponent = null;

    if (
      backTesingChartData?.backTestingSeriesData?.length > 0 &&
      backTesingChartData?.backTestingChartLabels?.length > 0
    ) {
      chartComponent = (
        <>
          {renderBackTestingAnalyticsHtml()}
          <LineColumnChart
            seriesData={backTesingChartData?.backTestingSeriesData}
            columnWidth={widthColumn}
            labels={sortChartLabels(
              backTesingChartData?.backTestingChartLabels
            )}
            leftTitle="Volume"
            rightTitle="Percentage"
          />
        </>
      );
    }

    return (
      <>
        {foreCastBackTestingChartData?.isChartDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          chartComponent
        )}
      </>
    );
  };

  const renderBackTestingTableHtml = (): ReactElement => {
    return (
      <>
        {foreCastBackTestingTableData?.isTableDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <BacktestingResultTable
            tableHeader={foreCastBackTestingTableData?.tableHeader}
            chartData={foreCastBackTestingChartData?.chartData}
            backTestingTableData={
              foreCastBackTestingTableData?.backTestingTableData
            }
            isResultVisulizationPage={isResultVisulizationPage}
            resultVisulizationTableData={[]}
            isExperimentationPage={isExperimentationPage}
          />
        )}
      </>
    );
  };

  const mapperForGroupByLableToKeyHandler = (
    selectedItemList: string[] = []
  ) => {
    const { selectedGroupByOptions, selectedCategoryType } =
      mapperForGroupByLableToKey(selectedItemList);
    setSelectedDataShownOptionList(selectedItemList);
    setSelectedDataShownCategoryList(selectedCategoryType);

    return selectedGroupByOptions;
  };

  const updateBackTestingTableData = (selectedOptionList: string[]) => {
    const payloadFilters = {
      shortTermMidTermCode: filter?.term.toString(),
      snapshotDate: [],
      brandName: filter?.brand,
      subBrandName: filter?.subBrand,
      productLineName: filter?.productLine,
      productGroupName: filter?.productGroup,
      materialGlobalTradeItemNumberDescription: filter?.planningItem,
      packageSizeName: filter?.size,
      productCategoryName: filter?.category,
      portfolioGroupName: filter?.mg4PortfolioGroup,
      packageName: filter?.container,
      keyAccount: filter?.keyAccount,
      caseCountValue: filter?.count,
      customerGlobalName: filter?.global,
      customerSectorNumber: filter?.customerSector,
      customerRegionName: filter?.customerRegion,
      customerGroupName: filter?.customerGroup,
      customerBusinessUnitName: filter?.businessUnit,
      customerMarketUnitName: filter?.marketUnit,
      ibpDemandGroup: filter?.ibpDemandGroup,
      customerTradeSegmentCode: filter?.cg1TrageSegment,
      salesSectorName: filter?.salesSector,
      salesRegionName: filter?.salesRegion,
      salesCountryCode: filter?.country,
      salesOrganizationCode: filter?.businessOrg,
      salesDivisionName: filter?.division,
      forecastEngineClusterId: filter?.clusters,
      forecastModuleCode: filter?.ac1ac2,
      forecastEngineSegmentationId: filter?.segment,
      packageTypeName: filter?.ppg,
    };

    const forecastBackTestingApiRequestBody = {
      ...payloadFilters,
      pageNumber: 1,
      pageSize: PAGE_LIMIT,
      groupBy: mapperForGroupByLableToKeyHandler(selectedOptionList),
    };

    setOpenSettings(false);
    dispatch(
      foreCastBackTestingTableDataSetter({
        tableHeader: [],
        backTestingTableData: [],
        isTableDataLoading: true,
      })
    );

    forecastBackTestingTableApi(forecastBackTestingApiRequestBody, authState)
      .then((response) => {
        const totalBackTestingPages = calculateTotalPages(
          PAGE_LIMIT,
          response?.totalCount
        );
        dispatch(
          updateBackTestingTotalPages({
            totalPages: totalBackTestingPages,
            pageLimit: PAGE_LIMIT,
            pageNumber: FIRST_PAGE,
            isDataShownClicked: true,
          })
        );
        dispatch(
          foreCastBackTestingTableDataSetter({
            tableHeader: response?.dynamicTableHeader,
            backTestingTableData: response?.dfuBasedGroupedData,
            isTableDataLoading: false,
          })
        );
      })
      .catch((error) => {
        console.log("forecastBackTestingTableApi error", error);
      });
  };

  const onModalClosed = () => {
    setOpenSettings(false);
  };

  const renderBackTestingDataShownModalHtml = (): ReactElement => {
    return (
      <>
        <DataShownDialog
          open={openSettings}
          defaultSelectedOptions={selectedDataShownOptionList}
          categoryList={selectedDataShownCategoryList}
          onClose={(itemList) => updateBackTestingTableData(itemList)}
          onModalClosed={onModalClosed}
        />
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "#272727",
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <div className="m-4">
              <div className="flex justify-between p-4">
                <h6 className="font-bold text-white">Backtesting Results</h6>
                <button onClick={handleClose}>
                  <img src={closeButton} alt="close" />
                </button>
              </div>

              <div className="bg-ternary-bg rounded m-4">
                {backTesingChartData?.backTestingSeriesData?.length > 0 &&
                  backTesingChartData?.backTestingChartLabels?.length > 0 && (
                    <LineColumnChart
                      seriesData={backTesingChartData?.backTestingSeriesData}
                      columnWidth={widthColumn}
                      labels={sortChartLabels(
                        backTesingChartData?.backTestingChartLabels
                      )}
                      leftTitle="Volume"
                      rightTitle="Percentage"
                    />
                  )}
                <BacktestingResultTable
                  tableHeader={foreCastBackTestingTableData?.tableHeader}
                  chartData={foreCastBackTestingChartData?.chartData}
                  backTestingTableData={
                    foreCastBackTestingTableData?.backTestingTableData
                  }
                  isResultVisulizationPage={isResultVisulizationPage}
                  resultVisulizationTableData={[]}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  const renderBackTestingHtml = (): ReactElement => {
    const isDataEmpty =
      !foreCastBackTestingDataLoading?.isDataLoading &&
      foreCastBackTestingChartData?.chartData?.length === 0 &&
      foreCastBackTestingAverageData?.overallAverage &&
      foreCastBackTestingAverageData?.overallPercent?.length === 0 &&
      foreCastBackTestingTableData?.backTestingTableData?.length === 0 &&
      resultVisualizationData?.chartData?.length === 0 &&
      !isResultVisulizationPage;

    return isDataEmpty ? (
      <div className="bg-ternary-bg rounded p-2">
        <div className="flex justify-center">
          <p className="p-2 m-1">
            No Data to show or no options selected, please choose from the above
            filters.
          </p>
        </div>
      </div>
    ) : (
      <>
        <div className="bg-ternary-bg rounded">
          {renderBackTestingChartHtml()}
          {renderBackTestingTableHtml()}
        </div>
        {renderBackTestingDataShownModalHtml()}
        <div className="flex justify-end">
          {isResultVisulizationPage ? (
            <> </>
          ) : (
            <div
              onClick={() => applyFilterAndRedirect()}
              style={{ backgroundColor: "#0069D7", borderRadius: "50px" }}
              className="font-bold text-base px-4 py-2 mt-4 cursor-pointer flex"
            >
              <span>View Alternative Recipes</span>
              <img src={arrowLogo} className="pl-2" alt="arrow" />
            </div>
          )}
        </div>
      </>
    );
  };

  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateBackTestingPageNumber({
        pageLimit: backTestingPagination?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: backTestingPagination?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: selectedDataShownOptionList,
      })
    );
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <div className="flex justify-between">
        <div className="flex">
          <h6 className="text-lg font-bold mb-4 mt-2">Backtesting Results</h6>
          <div className="m-2 rounded bg-zinc-600 pt-2">
            {filter?.term.includes("ST") ? (
              <span className="p-1">Lag 4</span>
            ) : (
              <span className="p-1">Lag 6</span>
            )}
          </div>
        </div>
        {!foreCastBackTestingDataLoading?.isDataLoading ||
        foreCastBackTestingChartData?.chartData?.length > 0 ||
        foreCastBackTestingTableData?.backTestingTableData?.length > 0 ? (
          <div>
            <button onClick={handleSettingModal}>
              <img
                className="mr-5"
                width={20}
                height={20}
                src={settingsIcon}
                alt="settingsIcon"
              />
            </button>
            <button onClick={handleOpen}>
              <img src={enlargeButton} alt="zoom" />
            </button>
          </div>
        ) : null}
      </div>
      <>
        {renderBackTestingHtml()}
        <div className="flex justify-center">
          {foreCastBackTestingTableData?.backTestingTableData?.length > 0 && (
            <Pagination
              totalPages={backTestingPagination?.totalPages}
              activePageNumber={backTestingPagination?.pageNumber}
              updateBackTestingPageNumber={(data) =>
                handlePageNumberUpdateEvent(data)
              }
            />
          )}
        </div>
      </>
    </div>
  );
}

export default BacktestingResults;
