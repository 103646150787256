import { useQuery } from "@tanstack/react-query";
import { getAPI } from "../API";

type ItemListByCategory = {
  [key: string]: string[];
};

const convertToItemListByCategory = (data: any): ItemListByCategory => {
  const result: ItemListByCategory = {};

  Object.keys(data).forEach((category) => {
    result[category] = Array.from(
      new Set(
        data[category]
          .filter((item: { name: string | null }) => item.name != null)
          .map((item: { name: string }) => item.name)
      )
    );
  });
  return result;
};

const fetchData = async (authToken: string, endpoint: string) => {
  try {
    const response = await getAPI(
      `forecast/filters/${endpoint}`,
      [],
      authToken
    );
    if (response.status === 200) {
      return convertToItemListByCategory(response?.data?.response);
    }
  } catch (error) {
    console.error(`Error fetching ${endpoint} data:`, error);
  }
};

const fetchClusterData = async (authToken: string, endpoint: string) => {
  try {
    const response = await getAPI(
      `forecast/filters/${endpoint}`,
      [],
      authToken
    );
    if (response.status === 200) {
      return response.data.response;
    }
  } catch (error) {
    console.error(`Error fetching ${endpoint} data:`, error);
  }
};

const fetchSnapshotDateData = async (authToken: string, endpoint: string) => {
  try {
    const response = await getAPI(
      `forecast/filters/snapshotdates/${endpoint}`,
      [],
      authToken
    );
    if (response.status === 200) {
      let res = response.data.response;
      return res;
    }
  } catch (error) {
    console.error(`Error fetching ${endpoint} data:`, error);
  }
};

export const useForecastClusterFilter = (
  authToken: string,
  endpoint: string
) => {
  return useQuery({
    queryKey: ["clusterData"],
    queryFn: () => fetchClusterData(authToken, endpoint),
    staleTime: 600000,
  });
};

export const useItemsApiData = (authToken: string, endpoint: string) => {
  return useQuery({
    queryKey: ["itemsData"],
    queryFn: () => fetchData(authToken, endpoint),
    staleTime: 600000,
  });
};

export const useCusomtersApiData = (authToken: string, endpoint: string) => {
  return useQuery({
    queryKey: ["customerData"],
    queryFn: () => fetchData(authToken, endpoint),
    staleTime: 600000,
  });
};

export const useSalesGeoApiData = (authToken: string, endpoint: string) => {
  return useQuery({
    queryKey: ["salesgeoData"],
    queryFn: () => fetchData(authToken, endpoint),
    staleTime: 600000,
  });
};

export const usePfeApiData = (authToken: string, endpoint: string) => {
  return useQuery({
    queryKey: ["pfeData"],
    queryFn: () => fetchData(authToken, endpoint),
    staleTime: 600000,
  });
};

export const useShortTermData = (authToken: string, endpoint: string) => {
  return useQuery({
    queryKey: ["shortTerm"],
    queryFn: () => fetchSnapshotDateData(authToken, endpoint),
    staleTime: 600000,
  });
};

export const useMidTermData = (authToken: string, endpoint: string) => {
  return useQuery({
    queryKey: ["midTerm"],
    queryFn: () => fetchSnapshotDateData(authToken, endpoint),
    staleTime: 600000,
  });
};
