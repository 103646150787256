import RcaExecutionFilter from "../../components/RcaFilters/RcaExecutionFilter"
import ClusterCard from "../StatisticalForecastAnalysis/Clustercard/clusterCard"
import TimeCardComponent from "../StatisticalForecastAnalysis/Timecard/TimeCardComponent"
import RcaSummaryCards from "./RcaSummaryCards/RcaSummaryCards"
import RcaSummaryTable from "./RcaTableSummary"

const RcaSummary = () => {
  return (
    <div>
      <div className="flex space-x-3 mt-5">
        <div style={{ width: '500px' }}>
          <TimeCardComponent lag={true} label="Forecast Date" isDisabled={false}/>
        </div>
        <div className="flex-auto">
          <ClusterCard />
        </div>
      </div>
      <RcaExecutionFilter />
      <RcaSummaryCards />
      <RcaSummaryTable />
    </div>
  )
}

export default RcaSummary