import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ForeCastBackTestingTableDataType,
  ForwardForecastTableDataType,
  initialForwardForecastTableState,
  initialLoadingState,
  initialPaginationState,
  initialTableState,
  PaginationType,
} from "./foreCastBackTestingDataSlice";

export interface ChartDataItem {
  accuracy: number;
  bias: number;
  statisticalForecast: number;
  historicalShipment: number;
  timeFrameDate: string;
  periodWeekNumber: string;
  experimentId: string;
  rank: number;
}

interface OverallAverage {
  accuracy: number;
  bias: number;
  historicalShipments: number;
  statisticalForecast: number;
}

interface OverallPercentItem {
  [key: string]: number;
}

export interface ProductionExperimentData {
  overallAverage: OverallAverage;
  overallPercent: OverallPercentItem[];
}

export interface ProductionExperimentDataWithRank
  extends ProductionExperimentData {
  rank: number;
}

export interface ExperimentDataWithRank {
  rank: number;
  overallAverage: OverallAverage;
  overallPercent: OverallPercentItem[];
}

export interface ExperimentationState {
  experimentIds: string[];
  forecastLagNumbers: number[];
  loader: boolean;
  error: string | null;
  productionData: ProductionExperimentDataWithRank;
  experimentData: ExperimentDataWithRank[];
  chartData: ChartDataItem[];
}

export const initialState: ExperimentationState = {
  experimentIds: [],
  forecastLagNumbers: [],
  loader: false,
  error: null,
  productionData: {
    rank: 0,
    overallAverage: {
      accuracy: 0.0,
      bias: 0.0,
      historicalShipments: 0.0,
      statisticalForecast: 0.0,
    },
    overallPercent: [],
  },
  experimentData: [],
  chartData: [],
};

export interface PromotionImpactDataItem {
  promoDiscount: number;
  maxPromotionImpact: number;
  minPromotionImpact: number;
  avgPromotionImpact: number;
  experimentId: string;
  rank: number;
  periodWeekNumber: string;
  previousYear: number;
  statisticalForecast: number;
  historicalShipment: number;
  timeFrameStartDate: string;
}

interface HolidaysData extends Record<string, string[]> {
  // New notation using Record
}

interface FFChartState {
  promotionImpactData: PromotionImpactDataItem[];
  holidaysData: HolidaysData;
}

const initialFFChartState: FFChartState = {
  promotionImpactData: [],
  holidaysData: {},
};

export const ResultVisualizationExperimentation = createSlice({
  name: "ResultVisualizationExperimentation",
  initialState,
  reducers: {
    setExperimentId: (
      state,
      action: PayloadAction<{
        experimentIds: string[];
        forecastLagNumbers: number[];
      }>
    ) => {
      state.experimentIds = action?.payload?.experimentIds;
      state.forecastLagNumbers = action?.payload?.forecastLagNumbers;
    },
    setProductionData: (
      state,
      action: PayloadAction<ProductionExperimentDataWithRank>
    ) => {
      state.productionData = action?.payload;
    },
    setExperimentationData: (
      state,
      action: PayloadAction<ExperimentDataWithRank[]>
    ) => {
      state.experimentData = action?.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action?.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action?.payload;
    },
    resetExperId: (state) => {
      return initialState;
    },
    resetStateExp: (state) => {
      return initialState;
    },
    setChartData: (state, action: PayloadAction<ChartDataItem[]>) => {
      state.chartData = action?.payload;
    },
  },
});
export const ResultVisulizationExperimentBackTestingTableDataSlice =
  createSlice({
    name: "resultVisulizationExperimentBackTestingTableDataSlice",
    initialState: initialTableState,
    reducers: {
      resultVisulizationExperimentBackTestingTableDataSetter: (
        state,
        action: PayloadAction<ForeCastBackTestingTableDataType>
      ) => {
        state.backTestingTableData = action?.payload?.backTestingTableData;
        state.tableHeader = action?.payload?.tableHeader;
        state.isTableDataLoading = action?.payload?.isTableDataLoading;
      },
      resetResultVisulizationExperimentBackTestingTableDataSetter: (state) => {
        state.backTestingTableData = [];
        state.tableHeader = [];
        state.isTableDataLoading = false;
      },
    },
  });

export const ResultVisulizationExperimentForwardForecastDataSlice = createSlice(
  {
    name: "resultVisulizationExperimentForwardForecastDataSlice",
    initialState: initialForwardForecastTableState,
    reducers: {
      resultVisulizationExperimentForwardForecastDataSetter: (
        state,
        action: PayloadAction<ForwardForecastTableDataType>
      ) => {
        state.backTestingTableData = action?.payload.backTestingTableData;
        state.tableHeader = action?.payload.tableHeader;
        state.isTableDataLoading = action?.payload.isTableDataLoading;
      },
      resetResultVisulizationExperimentForwardForecastDataSetter: (state) => {
        state.backTestingTableData = [];
        state.tableHeader = [];
        state.isTableDataLoading = false;
      },
    },
  }
);

export const ResultVisulizationExperimentTestingDataLoadingSlice = createSlice({
  name: "resultVisulizationExperimentTestingDataLoadingSlice",
  initialState: initialLoadingState,
  reducers: {
    resultVisulizationExperimentTestingDataLoading: (
      state,
      action: PayloadAction<ForeCastBackTestingTableDataType>
    ) => {
      state.isDataLoading = action?.payload.isTableDataLoading;
    },
    resetResultVisulizationExperimentTestingDataLoading: (state) => {
      state.isDataLoading = false;
    },
  },
});

export const ResultVisulizationExperimentForwardForecastDataLoadingSlice =
  createSlice({
    name: "ResultVisulizationExperimentForwardForecastDataLoadingSlice",
    initialState: initialLoadingState,
    reducers: {
      resultVisulizationExperimentForwardForecastDataLoading: (
        state,
        action: PayloadAction<ForeCastBackTestingTableDataType>
      ) => {
        state.isDataLoading = action?.payload.isTableDataLoading;
      },
      resetVisulizationExperimentForwardForecastDataLoading: (state) => {
        state.isDataLoading = false;
      },
    },
  });
export const ffchartSlice = createSlice({
  name: "ffchart",
  initialState: initialFFChartState,
  reducers: {
    setPromotionImpactData: (
      state,
      action: PayloadAction<PromotionImpactDataItem[]>
    ) => {
      state.promotionImpactData = action?.payload;
    },
    setHolidaysData: (state, action: PayloadAction<HolidaysData>) => {
      state.holidaysData = action?.payload;
    },
    resetFFChartState: () => initialFFChartState,
  },
});

export const ResultVisulizationExperimentBackTestingPagination = createSlice({
  name: "resultVisulizationExperimentBackTestingPagination",
  initialState: initialPaginationState,
  reducers: {
    updateExperinemtBackTestingPageNumber: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateExperinemtBackTestingPageLimit: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateExperinemtBackTestingTotalPages: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
  },
});

export const ResultVisulizationExperimentForwardForecastPagination =
  createSlice({
    name: "resultVisulizationExperimentForwardForecastPagination",
    initialState: initialPaginationState,
    reducers: {
      updateExperinemtForewardForecastPageNumber: (
        state,
        action: PayloadAction<PaginationType>
      ) => {
        state.pageLimit = action?.payload.pageLimit;
        state.totalPages = action?.payload.totalPages;
        state.pageNumber = action?.payload.pageNumber;
        state.isDataShownClicked = action?.payload.isDataShownClicked;
        state.groupBySelectedField = action?.payload.groupBySelectedField;
      },
      updateExperinemtForewardForecastPageLimit: (
        state,
        action: PayloadAction<PaginationType>
      ) => {
        state.pageLimit = action?.payload.pageLimit;
        state.totalPages = action?.payload.totalPages;
        state.pageNumber = action?.payload.pageNumber;
        state.isDataShownClicked = action?.payload.isDataShownClicked;
        state.groupBySelectedField = action?.payload.groupBySelectedField;
      },
      updateExperinemtForewardForecastTotalPages: (
        state,
        action: PayloadAction<PaginationType>
      ) => {
        state.pageLimit = action?.payload.pageLimit;
        state.totalPages = action?.payload.totalPages;
        state.pageNumber = action?.payload.pageNumber;
        state.isDataShownClicked = action?.payload.isDataShownClicked;
        state.groupBySelectedField = action?.payload.groupBySelectedField;
      },
    },
  });

export const {
  setExperimentId,
  setLoader,
  setError,
  resetExperId,
  resetStateExp,
  setProductionData,
  setExperimentationData,
  setChartData,
} = ResultVisualizationExperimentation.actions;

export const {
  updateExperinemtBackTestingPageLimit,
  updateExperinemtBackTestingTotalPages,
  updateExperinemtBackTestingPageNumber,
} = ResultVisulizationExperimentBackTestingPagination.actions;

export const {
  updateExperinemtForewardForecastPageLimit,
  updateExperinemtForewardForecastPageNumber,
  updateExperinemtForewardForecastTotalPages,
} = ResultVisulizationExperimentForwardForecastPagination.actions;

export const {
  resetResultVisulizationExperimentBackTestingTableDataSetter,
  resultVisulizationExperimentBackTestingTableDataSetter,
} = ResultVisulizationExperimentBackTestingTableDataSlice.actions;

export const {
  resetResultVisulizationExperimentForwardForecastDataSetter,
  resultVisulizationExperimentForwardForecastDataSetter,
} = ResultVisulizationExperimentForwardForecastDataSlice.actions;

export const {
  resetResultVisulizationExperimentTestingDataLoading,
  resultVisulizationExperimentTestingDataLoading,
} = ResultVisulizationExperimentTestingDataLoadingSlice.actions;

export const {
  resetVisulizationExperimentForwardForecastDataLoading,
  resultVisulizationExperimentForwardForecastDataLoading,
} = ResultVisulizationExperimentForwardForecastDataLoadingSlice.actions;

export const { setPromotionImpactData, setHolidaysData, resetFFChartState } =
  ffchartSlice.actions;

export default ResultVisualizationExperimentation.reducer;
