import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  TextField,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {
  FilterState,
  setForecastFilter,
  setRcaFilter,
  setResultVisualizationFilter,
} from "../../../store/filterSlice";

interface RankDropdownForwardProps {
  rankType: keyof FilterState;
}

const RankDropdownForward: React.FC<RankDropdownForwardProps> = ({
  rankType,
}) => {
  const dispatch = useDispatch();
  const [selectedRanks, setSelectedRanks] = useState<string[]>([]);

  // Available options
  const options = ["1", "2", "3"];

  // Set default selection
  useEffect(() => {
    setSelectedRanks(options); // Set default selected values to all options
    setSelectedRanks(options);
    dispatch(
      setResultVisualizationFilter({
        key: rankType,
        value: options.map((rank) => rank.toString()),
      })
    );

    dispatch(
      setForecastFilter({
        key: rankType,
        value: options.map((rank) => rank.toString()),
      })
    );
    dispatch(
      setRcaFilter({
        key: rankType,
        value: options.map((rank) => rank.toString()),
      })
    );
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        paper: "#272727",
      },
      text: {
        primary: "#ffffff",
      },
    },
  });

  const handleRankChange = (event: React.SyntheticEvent, value: string[]) => {
    setSelectedRanks(value);
    dispatch(
      setResultVisualizationFilter({
        key: rankType,
        value: value.map((rank) => rank.toString()),
      })
    );

    dispatch(
      setForecastFilter({
        key: rankType,
        value: value.map((rank) => rank.toString()),
      })
    );
    dispatch(
      setRcaFilter({
        key: rankType,
        value: value.map((rank) => rank.toString()),
      })
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="rank-dropdown-forward-container">
        <Autocomplete
          className="p-2 w-max"
          multiple
          options={options}
          getOptionLabel={(option) => option.toString()}
          value={selectedRanks}
          onChange={handleRankChange}
          renderInput={(params) => (
            <TextField
              {...params}
              className="flex"
              label={"Rank Shown"}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  borderColor: "#616161",
                },
                "& .MuiInputLabel-root": {
                  color: "#909090",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#616161",
                },
              }}
            />
          )}
          sx={{
            "& .MuiAutocomplete-popupIndicator": {
              color: "white",
            },
            "& .MuiAutocomplete-clearIndicator": {
              color: "white",
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
};

export default RankDropdownForward;
