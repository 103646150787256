import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import loadingSvg from "../../../images/bouncing-circles.svg";
import resetZoomIcon from "../../../images/resetZoom.svg";
import {
  PromotionImpactDataItem,
  updateExperinemtForewardForecastPageNumber,
} from "../../../store/resultVisualizationExperimentation";
import { Pagination } from "../../Pagination";
import { resultVisulizationExperimentDefaultGroupBy } from "../../../api/forecastPageApi";
import { BacktestingResultsProps } from "../../../store/foreCastBackTestingDataSlice";
import ForwardForecastTable from "../BacktestingResultTable/ForwardForecastTable";
import Linechart, { ChartAnnotation } from "../../chart/ScatterLine/lineChart";
import AnnotationLegend from "../../chart/ScatterLine/annotationLegends";
import RankDropdownForward from "./RankDropDown";

interface ChartPoint {
  x: string;
  y: number;
}

interface OutputData {
  promoDiscount: number;
  maxDiscount: number;
  avgDiscount: number;
  minDiscount: number;
  periodWeekNumber: string;
}

interface ChartSeries {
  type: "line" | "bar" | "scatter";
  label: string;
  data: ChartPoint[];
  borderColor?: string;
  backgroundColor?: string;
  borderWidth?: number;
  fill?: boolean;
  pointRadius?: number;
  yAxisID: string;
  pointStyle: string;
}

function ResultVisulizationExperimentForwardForecast({
  widthColumn,
  isResultVisulizationPage,
}: BacktestingResultsProps) {
  const dispatch = useDispatch();
  const [toolTipData, setToolTipData] = useState<any>([]);
  const [chartData, setChartData] = useState<ChartSeries[]>([]);
  const [annotation, setAnnotation] = useState<ChartAnnotation[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const chartRef = useRef<any>(null);
  const [selectedDataShownOptionList] = useState<string[]>(
    resultVisulizationExperimentDefaultGroupBy
  );
  const forwardData = useSelector(
    (state: RootState) => state.ffChart.promotionImpactData
  );

  const holidayData = useSelector(
    (state: RootState) => state.ffChart.holidaysData
  );
  function getSortedUniquePeriodWeekNumbers(
    table: any[],
    chart: any[],
    holidays: any
  ): string[] {
    const uniquePeriodWeekNumbers = new Set<string>([
      ...table?.map((item) => item.periodWeekNumber),
      ...chart?.map((item) => item.periodWeekNumber),
      ...Object.keys(holidays),
    ]);
    const periodWeekArray = Array.from(uniquePeriodWeekNumbers);

    const sortPeriodWeekNumbers = (a: string, b: string): number => {
      const matchA = a.match(/P(\d+)W(\d+) (\d+)/);
      const matchB = b.match(/P(\d+)W(\d+) (\d+)/);
      if (!matchA || !matchB) return 0;

      // Extract week and year from the matches
      const weekA = Number(matchA[2]); // Second capturing group
      const yearA = Number(matchA[3]); // Third capturing group
      const weekB = Number(matchB[2]); // Second capturing group
      const yearB = Number(matchB[3]); // Third capturing group

      // First sort by year
      if (yearA !== yearB) {
        return yearA - yearB;
      }

      // If years are the same, sort by week
      return weekA - weekB;
    };

    return periodWeekArray.sort(sortPeriodWeekNumbers);
  }

  const extractChartData = (chartData: any): ChartSeries[] => {
    const statisticalForecastProductionData: ChartPoint[] = chartData
      ?.filter((item: any) => item.experimentId === "ProductionRun")
      .map((item: any) => ({
        x: item.periodWeekNumber,
        y: item.statisticalForecast,
      }));

    const statisticalForecastRankData = (rank: number): ChartPoint[] =>
      chartData
        ?.filter((item: any) => item.rank === rank)
        .map((item: any) => ({
          x: item.periodWeekNumber,
          y: item.statisticalForecast,
        }));

    const historicalShipmentsData: ChartPoint[] = chartData?.map(
      (item: any) => ({
        x: item.periodWeekNumber,
        y: item.historicalShipment,
      })
    );

    const promoDiscountData: ChartPoint[] = chartData
      ?.filter(
        (item: any) =>
          (item.promoDiscount !== 0 && item.experimentId === "ProductionRun") ||
          item.promoDiscount === 0 ||
          item.maxPromotionImpact === 0 ||
          item.minPromotionImpact === 0 ||
          item.avgPromotionImpact === 0
      )
      .map((item: any) => ({
        x: item.periodWeekNumber,
        y:
          item.promoDiscount !== 0
            ? item.promoDiscount
            : item.maxPromotionImpact ||
              item.minPromotionImpact ||
              item.avgPromotionImpact,
      }));

    const previousYearData: ChartPoint[] = chartData?.map((item: any) => ({
      x: item.periodWeekNumber,
      y: item.previousYear,
    }));

    return [
      {
        type: "line",
        label: "Statistical Forecast Production",
        data: statisticalForecastProductionData,
        borderColor: "#B992EB",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        borderWidth: 2,
        pointStyle: "line",
        fill: false,
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Statistical Forecast Rank 1",
        data: statisticalForecastRankData(1),
        borderColor: "#33B1E8",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        borderWidth: 2,
        fill: false,
        pointStyle: "line",
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Statistical Forecast Rank 2",
        data: statisticalForecastRankData(2),
        borderColor: "#6E3BB2",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        borderWidth: 2,
        fill: false,
        pointStyle: "line",
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Statistical Forecast Rank 3",
        data: statisticalForecastRankData(3),
        borderColor: "#994900",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        borderWidth: 2,
        fill: false,
        pointStyle: "line",
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Historical Shipments (Adjusted)",
        data: historicalShipmentsData,
        borderColor: "#FFA800",
        backgroundColor: "rgba(185, 146, 235, 0.2)",
        borderWidth: 2,
        fill: false,
        yAxisID: "y",
        pointStyle: "line",
      },
      {
        type: "scatter",
        label: "Promo Discount",
        data: promoDiscountData,
        backgroundColor: "#DAB9C9",
        borderColor: "#DAB9C9",
        borderWidth: 2,
        pointRadius: 5,
        yAxisID: "y1",
        pointStyle: "circle",
      },
      {
        type: "bar",
        label: "Previous Year",
        data: previousYearData,
        backgroundColor: "#A35079",
        borderColor: "#A35079",
        borderWidth: 2,
        pointRadius: 6,
        yAxisID: "y",
        pointStyle: "rect",
      },
    ];
  };
  const holidaySpecificColors: Record<string, string> = {
    "Super Bowl": "#FF4500",
    Thanksgiving: "#FF6347",
    "New Year's Day": "#1E90FF",
    Easter: "#FF69B4",
    "Quarter 1 Close": "#2E8B57",
    "Winter Holiday (Q4 Close)": "#8A2BE2",
    "Labor Day (Q3 Close)": "#FFD700",
    "Memorial Day": "#DC143C",
    "Quarter 2 Close": "#20B2AA",
    "Independence Day": "#FF4500",
    "Cinco De Mayo": "#32CD32",
  };

  const getRandomColor = (): string => {
    const array = new Uint8Array(3);
    crypto.getRandomValues(array);
    const [r, g, b] = array;
    return `rgba(${r}, ${g}, ${b}, 1)`;
  };

  const getHolidayColor = (
    holiday: string,
    colorMap: Record<string, string>
  ): string => {
    if (holidaySpecificColors[holiday]) {
      return holidaySpecificColors[holiday];
    }

    if (!colorMap[holiday]) {
      colorMap[holiday] = getRandomColor();
    }

    return colorMap[holiday];
  };

  const mapHolidaysToAnnotations = (
    holidayData: Record<string, string[]>
  ): { annotations: ChartAnnotation[] } => {
    const annotations: ChartAnnotation[] = [];
    const holidayColorMap: Record<string, string> = {};

    Object.keys(holidayData)?.forEach((key) => {
      const [period, year] = key.split(" ");
      holidayData[key]?.forEach((holiday) => {
        const holidayColor = getHolidayColor(holiday, holidayColorMap);

        annotations.push({
          type: "line",
          borderColor: holidayColor,
          borderWidth: 2,
          value: `${period} ${year}`,
          scaleID: "x",
          label: {
            backgroundColor: holidayColor,
            display: true,
            color: "#fff",
            content: `${holiday} (${year})`,
          },
        });
      });
    });

    // Sort annotations by period, week, and year
    annotations.sort((a, b) => {
      const [periodA, weekA, yearA] = (
        a.value.match(/P(\d{2})W(\d{2}) (\d{4})/)?.slice(1) || []
      ).map(Number);
      const [periodB, weekB, yearB] = (
        b.value.match(/P(\d{2})W(\d{2}) (\d{4})/)?.slice(1) || []
      ).map(Number);
      return yearA - yearB || periodA - periodB || weekA - weekB;
    });

    return { annotations };
  };

  const { annotations } = useMemo(
    () => mapHolidaysToAnnotations(holidayData),
    [holidayData]
  );

  const resetZoom = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    } else {
      console.error("Chart ref is not available");
    }
  }, [chartRef]);

  function extractToolTipData(data: PromotionImpactDataItem[]): OutputData[] {
    const seen = new Set<string>();
    return data
      ?.filter((item) => item.experimentId === "ProductionRun")
      .map((item) => ({
        promoDiscount: item.promoDiscount ?? 0,
        maxDiscount: item.maxPromotionImpact ?? 0,
        avgDiscount: item.avgPromotionImpact ?? 0,
        minDiscount: item.minPromotionImpact ?? 0,
        periodWeekNumber: item.periodWeekNumber ?? "N/A",
      }))
      .filter(
        (obj) =>
          obj.promoDiscount !== 0 ||
          obj.maxDiscount !== 0 ||
          obj.avgDiscount !== 0 ||
          obj.minDiscount !== 0
      )
      .filter((obj) => {
        const key = JSON.stringify(obj);
        if (seen.has(key)) {
          return false;
        }
        seen.add(key);
        return true;
      });
  }

  useEffect(() => {
    const labelsTemp = getSortedUniquePeriodWeekNumbers(
      forwardData,
      resultVisulizationExperimentForwardForecastDataSlice?.backTestingTableData,
      holidayData
    );
    setLabels(labelsTemp);
    setChartData(extractChartData(forwardData));
    setAnnotation(annotations);
    setToolTipData(extractToolTipData(forwardData));
  }, [forwardData, holidayData]);

  const resultVisualizationExperimentationData = useSelector(
    (state: RootState) => state.resultVisualizationExperimentationData
  );

  const loader = useSelector(
    (state: RootState) => state.resultVisualizationData
  );

  const resultVisulizationExperimentForwardForecastDataSlice = useSelector(
    (state: RootState) =>
      state.resultVisulizationExperimentForwardForecastDataSlice
  );

  const resultVisulizationExperimentForwardForecastDataLoadingSlice =
    useSelector(
      (state: RootState) =>
        state.resultVisulizationExperimentForwardForecastDataLoadingSlice
    );

  const resultVisulizationForewardForecastPagination = useSelector(
    (state: RootState) => state.resultVisulizationForewardForecastPagination
  );

  const forwardForecastChartData = useSelector(
    (state: RootState) => state.ffChart
  );

  const renderBackTestingTableHtml = (): ReactElement => {
    return (
      <>
        {resultVisulizationExperimentForwardForecastDataSlice?.isTableDataLoading ? (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <div className="bg-ternary-bg rounded p-2">
            {chartData?.length > 0 ? (
              <div className="flex">
                <Linechart
                  chartData={{ datasets: chartData }}
                  chartRef={chartRef}
                  labels={labels}
                  chartAnnotations={annotation}
                  customTooltipData={toolTipData}
                />
                <div className="overscroll-contain h-06">
                  <AnnotationLegend
                    legendItems={annotation}
                    labelOrder={labels}
                  />
                </div>
                <div className="pt-2">
                  <button onClick={resetZoom}>
                    <img src={resetZoomIcon} alt="reset zoom" />
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-ternary-bg rounded p-2">
                <div className="flex justify-center">
                  <p className="p-2 m-1">
                    No Data to show or no options selected, please choose from
                    the above filters.
                  </p>
                </div>
              </div>
            )}
            <ForwardForecastTable
              tableHeader={
                resultVisulizationExperimentForwardForecastDataSlice?.tableHeader
              }
              chartData={forwardForecastChartData?.promotionImpactData}
              backTestingTableData={
                resultVisulizationExperimentForwardForecastDataSlice?.backTestingTableData
              }
              isResultVisulizationPage={isResultVisulizationPage}
              resultVisulizationTableData={
                resultVisualizationExperimentationData.chartData
              }
              isExperimentationPage={true}
            />
          </div>
        )}
      </>
    );
  };

  const renderBackTestingHtml = (): ReactElement => {
    const isDataEmpty =
      !resultVisulizationExperimentForwardForecastDataLoadingSlice?.isDataLoading &&
      resultVisulizationExperimentForwardForecastDataSlice?.backTestingTableData
        ?.length === 0 &&
      resultVisualizationExperimentationData?.chartData?.length === 0;
  
    if (isDataEmpty) {
      if (loader) {
        return (
          <div className="bg-ternary-bg rounded p-2">
            <div className="flex justify-center">
              <p className="p-2 m-1">
                No Data to show or no options selected, please choose from the
                above filters.
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex justify-center">
            <img
              src={loadingSvg}
              alt="loading"
              className="mt-10"
              width={40}
              height={40}
            />
          </div>
        );
      }
    }
  
    return (
      <>
        <div className="bg-ternary-bg rounded">{renderBackTestingTableHtml()}</div>
      </>
    );
  };
  
  const handlePageNumberUpdateEvent = (currentPageNumber: number) => {
    dispatch(
      updateExperinemtForewardForecastPageNumber({
        pageLimit: resultVisulizationForewardForecastPagination?.pageLimit,
        pageNumber: currentPageNumber,
        totalPages: resultVisulizationForewardForecastPagination?.totalPages,
        isDataShownClicked: false,
        groupBySelectedField: selectedDataShownOptionList,
      })
    );
  };

  return (
    <div className="p-5 bg-secondary-bg mt-4 rounded">
      <div className="flex justify-between">
        <div className="flex mb-2">
          <div className="pl-2">
            <h6 className="text-lg font-bold mb-4 mt-2 pt-2">
              Forward Forecast Results
            </h6>
          </div>
          <div className="pl-2">
            <RankDropdownForward rankType="rankForward" />
          </div>
        </div>

        {!resultVisulizationExperimentForwardForecastDataLoadingSlice?.isDataLoading ||
        resultVisulizationExperimentForwardForecastDataSlice
          ?.backTestingTableData?.length > 0 ? (
          <div>
            {/* <button onClick={handleSettingModal}>
              <img
                className="mr-5"
                width={20}
                height={20}
                src={settingsIcon}
                alt="settingsIcon"
              />
            </button> */}
            {/* <button onClick={handleOpen}>
              <img src={enlargeButton} alt="zoom" />
            </button> */}
          </div>
        ) : null}
      </div>
      <>
        {renderBackTestingHtml()}
        <div className="flex justify-center">
          {resultVisulizationExperimentForwardForecastDataSlice
            ?.backTestingTableData?.length > 0 && (
            <div className="pt-2">
              <Pagination
                totalPages={
                  resultVisulizationForewardForecastPagination?.totalPages
                }
                activePageNumber={
                  resultVisulizationForewardForecastPagination?.pageNumber
                }
                updateBackTestingPageNumber={(data) =>
                  handlePageNumberUpdateEvent(data)
                }
              />
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export default ResultVisulizationExperimentForwardForecast;
