import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccuracyData, PaginationType } from "./foreCastBackTestingDataSlice";
import { PAGE_LIMIT } from "../utils/constant";

export interface ForeCastApiChartType {
  statisticalForecast: number;
  historicalShipment: number;
  promoDiscount: number;
  maxPromotionImpact: number;
  minPromotionImpact: number;
  avgPromotionImpact: number;
  timeFrameDate: string;
  periodWeekNumber: string;
}

export interface HistoricalShipmentType {
  clusterId: string;
  upc: string;
  keyAccount: string;
  salesRegion: string;
  dataType: string;
  ltp: {
    forecastAccuracy: AccuracyData[];
    historicalShipments: AccuracyData[];
  };
}

export interface ForeCastApiResponseType {
  isForcastTableDataLoading: boolean;
  chartData: ForeCastApiChartType[];
  holidayIndicators: Record<string, string[]>;
  tableRowData: [];
  dynamicTableHeader: string[];
}

export const initialState: ForeCastApiResponseType = {
  chartData: [],
  holidayIndicators: {},
  tableRowData: [],
  dynamicTableHeader: [],
  isForcastTableDataLoading: false,
};

export const initialPaginationState: PaginationType = {
  pageNumber: 1,
  pageLimit: PAGE_LIMIT,
  totalPages: 0,
  isDataShownClicked: false,
  groupBySelectedField: []
};

export const forecastDataSlice = createSlice({
  name: "forecastDataSlice",
  initialState,
  reducers: {
    setChartData: (state, action: PayloadAction<ForeCastApiChartType[]>) => {
      state.chartData = action?.payload;
    },
    setHolidayIndicators: (
      state,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.holidayIndicators = action?.payload;
    },
    setTableRowData: (
      state,
      action: PayloadAction<[]>
    ) => {
      state.tableRowData = action?.payload
    },
    setDynamicTableHeader: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.dynamicTableHeader = action?.payload;
    },
    setPageLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isForcastTableDataLoading = action?.payload;
    },
    foreCastApiDataSetter: (
      state,
      action: PayloadAction<ForeCastApiResponseType>
    ) => {
      state.chartData = action?.payload.chartData;
      state.holidayIndicators = action?.payload.holidayIndicators;
      state.isForcastTableDataLoading = action?.payload.isForcastTableDataLoading;
      state.tableRowData = action?.payload.tableRowData;
      state.dynamicTableHeader = action?.payload.dynamicTableHeader;
    },
    // Reset state to initialState
    resetForecastData: () => initialState,
  },
});

export const foreCastAnalysisPagination = createSlice({
  name: "foreCastAnalysisPagination",
  initialState: initialPaginationState,
  reducers: {
    updateForecastAnalysisPageNumber: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateForecastAnalysisPageLimit: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateForecastAnalysisTotalPages: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    resetPaginationState: (state) => {
      state.pageNumber = 1;
      state.pageLimit = PAGE_LIMIT;
      state.totalPages = 0;
      state.isDataShownClicked = false;
      state.groupBySelectedField = []
    },
  },
});

export const {
  setChartData,
  setHolidayIndicators,
  setTableRowData,
  setDynamicTableHeader,
  foreCastApiDataSetter,
  setPageLoadingState,
  resetForecastData,
} = forecastDataSlice.actions;

export const {
  updateForecastAnalysisPageLimit,
  updateForecastAnalysisPageNumber,
  updateForecastAnalysisTotalPages,
  resetPaginationState,
} = foreCastAnalysisPagination.actions;

export default forecastDataSlice.reducer;
