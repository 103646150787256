import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SeriesData } from "./chartSlice";
import { PAGE_LIMIT } from "../utils/constant";
import { PromotionImpactDataItem } from "./resultVisualizationExperimentation";

export interface ChartData {
  accuracy: number;
  statisticalForecast: number;
  historicalShipment: number;
  periodWeekNumber: string;
  bias: number;
}

export interface AccuracyData {
  weekNumber: string | null;
  value: number;
  rank?:number;
  periodWeekNumber?: string | null;
  timeFrameStartDate?:string;
}

export interface ResultVisulizationData {
  value: string | null;
}

export interface BackTesingListingTablData {
  model: string;
  clusterId: string;
  upc: string;
  keyAccount: string;
  salesRegion: string;
  dataType: string | null;
  ltp: {
    forecastAccuracy: AccuracyData[];
    bias: AccuracyData[];
    statisticalForecast: AccuracyData[];
    historicalShipments: AccuracyData[];
    biasExperimentation?: AccuracyData[];
    biasProduction?: AccuracyData[];
    forecastAccuracyExperimentation?: AccuracyData[];
    forecastAccuracyProduction?: AccuracyData[];
  };
}

export interface ForecastListingTablData {
  model: string;
  clusterId: string;
  upc: string;
  keyAccount: string;
  salesRegion: string;
  dataType: string | null;
  ltp: {
    statisticalForecastExperimentation: AccuracyData[];
    statisticalForecastProduction: AccuracyData[];
    historicalShipments: AccuracyData[];
  };
}

export interface OverallPercentType {
  accuracy: number;
  bias: number;
  historicalShipments: number;
  statisticalForecast: number;
}

export interface BackTesingListingResultVisulizationTablData {
  dataType: string | null;
  ltp: {
    forecastAccuracy: ResultVisulizationData[];
    bias: ResultVisulizationData[];
  };
}

export interface BackTestingProductionSectionType { }

export interface BackTestingResultsTableProps {
  tableHeader: string[];
  chartData: ChartData[];
  backTestingTableData: BackTesingListingTablData[];
  resultVisulizationTableData: any[];
  isResultVisulizationPage: boolean;
  isExperimentationPage?: boolean;
}

export interface ForwardForcatResultsTableProps {
  tableHeader: string[];
  chartData: PromotionImpactDataItem[];
  backTestingTableData: ForecastListingTablData[];
  resultVisulizationTableData: any[];
  isResultVisulizationPage: boolean;
  isExperimentationPage?: boolean;
}

export interface BacktestingResultsProps {
  widthColumn: string;
  isResultVisulizationPage: boolean;
  isExperimentationPage: boolean
}

export interface BackTestingAnalyticsType {
  overallAverage: OverallPercentType;
  overallPercent: [];
}

export interface BackTestingChartData {
  backTestingSeriesData: SeriesData[];
  backTestingChartLabels: string[];
}

export interface ForeCastBackTestingTableDataType {
  tableHeader: string[];
  backTestingTableData: BackTesingListingTablData[];
  isTableDataLoading: boolean;
}

export interface ForwardForecastTableDataType {
  tableHeader: string[];
  backTestingTableData: ForecastListingTablData[];
  isTableDataLoading: boolean;
}

export interface ForeCastBackTestingChartDataType {
  backTestingChartLabels: string[];
  chartData: ChartData[];
  isChartDataLoading: boolean;
}

export interface ForeCastBackTestingAverageDataType {
  overallAverage: {
    accuracy: 0;
    bias: 0;
    historicalShipments: 0;
    statisticalForecast: 0;
  };
  overallPercent: [];
  isAverageDataLoading: boolean;
}

export const initialTableState: ForeCastBackTestingTableDataType = {
  tableHeader: [],
  backTestingTableData: [],
  isTableDataLoading: false,
};

export const initialForwardForecastTableState: ForwardForecastTableDataType = {
  tableHeader: [],
  backTestingTableData: [],
  isTableDataLoading: false,
};

export const initialChartState: ForeCastBackTestingChartDataType = {
  backTestingChartLabels: [],
  chartData: [],
  isChartDataLoading: false,
};

export const initialAverageState: ForeCastBackTestingAverageDataType = {
  overallAverage: {
    accuracy: 0,
    bias: 0,
    historicalShipments: 0,
    statisticalForecast: 0,
  },
  overallPercent: [],
  isAverageDataLoading: false,
};

interface ForecastBackTestingLoadingType {
  isDataLoading: boolean;
}

export interface PaginationType {
  pageNumber: number;
  pageLimit: number;
  totalPages: number;
  isDataShownClicked: boolean;
  groupBySelectedField?: string[];
}

export const initialPaginationState: PaginationType = {
  pageNumber: 1,
  pageLimit: PAGE_LIMIT,
  totalPages: 0,
  isDataShownClicked: false,
  groupBySelectedField: []
};

export const initialLoadingState: ForecastBackTestingLoadingType = {
  isDataLoading: false,
};

export const foreCastBackTestingTableDataSlice = createSlice({
  name: "foreCastBackTestingTableDataSlice",
  initialState: initialTableState,
  reducers: {
    foreCastBackTestingTableDataSetter: (
      state,
      action: PayloadAction<ForeCastBackTestingTableDataType>
    ) => {
      state.backTestingTableData = action?.payload.backTestingTableData;
      state.tableHeader = action?.payload.tableHeader;
      state.isTableDataLoading = action?.payload.isTableDataLoading;
    },
    resetForecastBackTestingTableState: (state) => {
      state.backTestingTableData = []
      state.tableHeader = []
      state.isTableDataLoading = false
    },
  },
});

export const foreCastBackTestingChartDataSlice = createSlice({
  name: "foreCastBackTestingChartDataSlice",
  initialState: initialChartState,
  reducers: {
    foreCastBackTestingChartDataSetter: (
      state,
      action: PayloadAction<ForeCastBackTestingChartDataType>
    ) => {
      state.chartData = action?.payload.chartData;
      state.backTestingChartLabels = action?.payload.backTestingChartLabels;
      state.isChartDataLoading = action?.payload.isChartDataLoading;
    },
    resetForecastBackTestingChartState: (state) => {
      state.backTestingChartLabels = [];
      state.chartData = [];
      state.isChartDataLoading = false;
    },
  },
});

export const foreCastBackTestingAverageDataSlice = createSlice({
  name: "foreCastBackTestingAverageDataSlice",
  initialState: initialAverageState,
  reducers: {
    foreCastBackTestingAverageDataSetter: (
      state,
      action: PayloadAction<ForeCastBackTestingAverageDataType>
    ) => {
      state.overallAverage = action?.payload.overallAverage;
      state.overallPercent = action?.payload.overallPercent;
      state.isAverageDataLoading = action?.payload.isAverageDataLoading;
    },
    resetForecastBackTestingAverageState: (state) => {
      state.overallAverage = {
        accuracy: 0,
        bias: 0,
        historicalShipments: 0,
        statisticalForecast: 0,
      };
      state.overallPercent = []
      state.isAverageDataLoading = false
    },
  },
});

export const foreCastBackTestingDataLoadingSlice = createSlice({
  name: "forecastBackTestingDataLoadingSlice",
  initialState: initialLoadingState,
  reducers: {
    foreCastBackTestingApiDataLoading: (
      state,
      action: PayloadAction<ForecastBackTestingLoadingType>
    ) => {
      state.isDataLoading = action?.payload.isDataLoading;
    },
    resetForecastBackTestingApiDataLoading: (state) => {
      state.isDataLoading = false;
    },
  },
});

export const foreCastBackTestingPagination = createSlice({
  name: "forecastBackTestingPagination",
  initialState: initialPaginationState,
  reducers: {
    updateBackTestingPageNumber: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateBackTestingPageLimit: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateBackTestingTotalPages: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    resetBackTestingPaginationState: (state) => {
      state.pageNumber =1;
      state.pageLimit = PAGE_LIMIT;
      state.totalPages = 0;
      state.isDataShownClicked = false;
      state.groupBySelectedField = []
    },
  },
});

export const {
  foreCastBackTestingTableDataSetter,
  resetForecastBackTestingTableState,
} = foreCastBackTestingTableDataSlice.actions;

export const {
  foreCastBackTestingChartDataSetter,
  resetForecastBackTestingChartState,
} = foreCastBackTestingChartDataSlice.actions;

export const {
  foreCastBackTestingAverageDataSetter,
  resetForecastBackTestingAverageState,
} = foreCastBackTestingAverageDataSlice.actions;

export const { foreCastBackTestingApiDataLoading, resetForecastBackTestingApiDataLoading } =
  foreCastBackTestingDataLoadingSlice.actions;

export const { updateBackTestingPageLimit, updateBackTestingPageNumber, updateBackTestingTotalPages, resetBackTestingPaginationState } =
  foreCastBackTestingPagination.actions;

export default foreCastBackTestingTableDataSlice.reducer;
