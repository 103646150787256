// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backtestingResultDataRow {
  width: 91px;
  font-weight: normal;
}

.btr-table-holder::-webkit-scrollbar {
  height: 10px; /* Height of horizontal scrollbar */
  background-color: grey; /* Background color of the scrollbar track */
  width: 10px;
  border-radius: 10px;
}

.btr-table-holder::-webkit-scrollbar-corner {
  background-color: #1d1d1d;
}

.btr-table-holder::-webkit-scrollbar-thumb {
  background-color: rgb(55, 55, 55); /* Color of the scrollbar thumb */
  border-radius: 10px;
  border: 2px solid grey;
}

.btr-table-holder {
  overflow-x: scroll;
  width: 100%;
  min-height: 50vh;
  margin: 10px;
  padding-top: 10px;
}

.btr-accurey-table-tr {
  background: #272727;
}

.btr-accurey-table-span {
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/ForecastAnalysis/ForecastAnalysisTable/ForecastAnalysisTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,YAAY,EAAE,mCAAmC;EACjD,sBAAsB,EAAE,4CAA4C;EACpE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC,EAAE,iCAAiC;EACpE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".backtestingResultDataRow {\n  width: 91px;\n  font-weight: normal;\n}\n\n.btr-table-holder::-webkit-scrollbar {\n  height: 10px; /* Height of horizontal scrollbar */\n  background-color: grey; /* Background color of the scrollbar track */\n  width: 10px;\n  border-radius: 10px;\n}\n\n.btr-table-holder::-webkit-scrollbar-corner {\n  background-color: #1d1d1d;\n}\n\n.btr-table-holder::-webkit-scrollbar-thumb {\n  background-color: rgb(55, 55, 55); /* Color of the scrollbar thumb */\n  border-radius: 10px;\n  border: 2px solid grey;\n}\n\n.btr-table-holder {\n  overflow-x: scroll;\n  width: 100%;\n  min-height: 50vh;\n  margin: 10px;\n  padding-top: 10px;\n}\n\n.btr-accurey-table-tr {\n  background: #272727;\n}\n\n.btr-accurey-table-span {\n  font-size: 10px;\n  font-weight: 700;\n  line-height: 14px;\n  letter-spacing: 1px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
