import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  initialPaginationState,
  PaginationType,
} from "./foreCastBackTestingDataSlice";
import { PAGE_LIMIT } from "../utils/constant";

interface RcaTreeGraphItem {
  errorQuantity: number;
  rcaStat: string;
}

interface TableDataItem {
  salesRegionCode: string;
  materialGlobalTradeItemNumber: string;
  demandPlanningKeyAccountId: string;
  absoluteErrorQuantity: number;
  finalRcaName: string;
  rcaStatPercent: number;
  snapshotDate: string;
  pfeAccuracy: number;
  pfeBias: number;
  statisticalForecast: number;
}

interface SecondTable {
  forecastEngineClusterId: string;
  salesRegionCode: string;
  materialGlobalTradeItemNumber: string;
  demandPlanningKeyAccountId: string;
  absoluteErrorQuantity: number;
  finalRcaName: string;
  rcaStatPercent: number;
  rcaStat: string;
  snapshotDate: string;
  pfeAccuracy: number;
  pfeBias: number;
  statisticalForecast: number;
}

interface ForecastState {
  accuracy: number;
  bias: number;
  dpForecastAccuracy: number;
  dpForecastBias: number;
  dpForecastValueAdd: number;
  rcaTreeGraph: RcaTreeGraphItem[];
  rcaStats: string[];
  tableData: TableDataItem[];
  isTableDataLoading: boolean;
  summaryTable: TableDataItem[];
  secondTable: { data: SecondTable[]; totalCount: number };
}

const initialState: ForecastState = {
  accuracy: 0,
  bias: 0,
  dpForecastAccuracy: 0,
  dpForecastBias: 0,
  dpForecastValueAdd: 0,
  rcaTreeGraph: [],
  rcaStats: [],
  tableData: [],
  isTableDataLoading: false,
  summaryTable: [],
  secondTable: { data: [], totalCount: 0 },
};

export const rcaSlice = createSlice({
  name: "forecast",
  initialState,
  reducers: {
    setMetrics(state, action: PayloadAction<ForecastState>) {
      return action?.payload;
    },
    updateMetrics(state, action: PayloadAction<Partial<ForecastState>>) {
      return { ...state, ...action?.payload };
    },
    setRcaTreeGraph(state, action: PayloadAction<RcaTreeGraphItem[]>) {
      state.rcaTreeGraph = action?.payload;
    },
    setRcaStats(state, action: PayloadAction<string[]>) {
      state.rcaStats = action?.payload;
    },
    setTableData(state, action: PayloadAction<TableDataItem[]>) {
      state.tableData = action?.payload;
    },
    setTableLoading(state, action: PayloadAction<boolean>) {
      state.isTableDataLoading = action?.payload;
    },
    resetTableData(state) {
      state.tableData = [];
    },
    resetTreeChartData(state) {
      state.rcaTreeGraph = [];
    },
    setSummaryTable(state, action: PayloadAction<TableDataItem[]>) {
      state.summaryTable = action?.payload;
    },
    setSecondTable(state, action: PayloadAction<{ data: SecondTable[]; totalCount: number }>) {
      state.secondTable = {'data': action?.payload?.data,  'totalCount' : action?.payload?.totalCount}
    },
    resetSecondTable(state) {
      state.secondTable.data = [];
    },
    resetSummaryTable(state) {
      state.summaryTable = [];
    },
    resetMetrics() {
      return initialState;
    },
  },
});

export const rcaExecutionPaginationSlice = createSlice({
  name: "rcaExecutionPagination",
  initialState: initialPaginationState,
  reducers: {
    updateExecutionBackTestingPageNumber: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateExecutionBackTestingPageLimit: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateExecutionBackTestingTotalPages: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    resetExecutionBackTesting: (state) => {
      state.pageLimit = PAGE_LIMIT;
      state.totalPages = 0;
      state.pageNumber = 1;
      state.isDataShownClicked = false;
      state.groupBySelectedField = [];
    },
  },
});

export const rcaSummaryPaginationSlice = createSlice({
  name: "rcaSummaryPagination",
  initialState: initialPaginationState,
  reducers: {
    updateSummaryBackTestingPageNumber: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateSummaryBackTestingPageLimit: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    updateSummaryBackTestingTotalPages: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
      state.groupBySelectedField = action?.payload.groupBySelectedField;
    },
    resetSummaryBackTestingPagination: (state) => {
      state.pageLimit = PAGE_LIMIT;
      state.totalPages = 0;
      state.pageNumber = 1;
      state.isDataShownClicked = false;
      state.groupBySelectedField = [];
    },
  },
});

export const {
  updateExecutionBackTestingPageLimit,
  updateExecutionBackTestingPageNumber,
  updateExecutionBackTestingTotalPages,
  resetExecutionBackTesting,
} = rcaExecutionPaginationSlice.actions;

export const {
  updateSummaryBackTestingPageLimit,
  updateSummaryBackTestingPageNumber,
  updateSummaryBackTestingTotalPages,
  resetSummaryBackTestingPagination,
} = rcaSummaryPaginationSlice.actions;

export const {
  setMetrics,
  updateMetrics,
  setTableLoading,
  setRcaTreeGraph,
  setRcaStats,
  setTableData,
  resetTableData,
  resetSecondTable,
  setSummaryTable,
  setSecondTable,
  resetTreeChartData,
  resetSummaryTable,
  resetMetrics,
} = rcaSlice.actions;

export default rcaSlice.reducer;
