import React, { useState } from 'react';
import { Button, List, ListItem, TextField } from '@mui/material';

type SelectableListProps = {
    items: { id: string; label: string }[];
    onCancel: () => void;
    onUpdate: (selectedValue: string | null) => void;
};

const SelectableList: React.FC<SelectableListProps> = ({ items, onCancel, onUpdate }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    const handleSelect = (value: string) => {
        setSelectedValue(value);
    };

    const filteredItems = items.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div style={{ padding: '16px' }} >
            <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ marginBottom: '16px' }}
            />
            <List>
                {filteredItems.map((item) => (
                    <ListItem
                        key={item.id}
                        button
                        selected={selectedValue === item.label}
                        onClick={() => handleSelect(item.label)}
                    >
                        {item.label}
                    </ListItem>
                ))}
            </List>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                <Button variant="outlined" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onUpdate(selectedValue)}
                    disabled={!selectedValue}
                >
                    Update
                </Button>
            </div>
        </div>
    );
};

export default SelectableList;
