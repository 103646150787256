import React from "react";
import "./anotattionsCss.css";
import { ChartAnnotation } from "./lineChart";
const uniqueKey = require("unique-key");

interface AnnotationLegendProps {
  legendItems: ChartAnnotation[];
  labelOrder: string[];
  label?: string;
}

const AnnotationLegend: React.FC<AnnotationLegendProps> = ({
  legendItems,
  labelOrder,
}) => {
  const sortedLegendItems = [...legendItems].sort((a, b) => {
    const indexA = labelOrder.indexOf(a.value);
    const indexB = labelOrder.indexOf(b.value);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    return 0;
  });

  return (
    <div className="anot-hold flex flex-col h-64 overflow-y-scroll space-y-1 ml-5 mt-20">
      {sortedLegendItems.map((holidayName) => (
        <div
          key={uniqueKey("annotation_legends")}
          className="flex items-center space-x-2"
        >
          <span
            className="w-2.5 h-1"
            style={{ backgroundColor: holidayName.label.backgroundColor }}
          ></span>
          <span className="text-white text-sm">
            {holidayName.label.content}
          </span>
        </div>
      ))}
    </div>
  );
};

export default AnnotationLegend;
