import ForecastAnalysis from "./ForecastAnalysis";
import ForecastDecomposition from "./ForecastDecomposition";
import ClusterCard from "./Clustercard/clusterCard";
import BacktestingResults from "./BacktestingResults";
import ForecastAnalysisFilter from "../../components/ForecastAnalysisFilter/ForecastAnalysisFilter";
import { useState } from "react";
import { PageLoader } from "../../baseComponent";
import TimeCardComponent from "./Timecard/TimeCardComponent";

export const StatisticalForecastAnalysis = () => {
  const [showPageLoader, setShowPageLoader] = useState<boolean>(false);
  const pageLoaderHandler = (loadingState: boolean): void => {
    setShowPageLoader(loadingState);
  };

  return (
    <div className="p-5">
      <div className="flex justify-between mb-7">
        <span className="text-3xl font-bold">
          Statistical Forecast Analysis
        </span>
        <span>Sector: PBNA | Market: US</span>
      </div>
      <div className="mb-5">
        <span className="text-lg font-bold">Select Criteria</span>
      </div>
      <div className="flex space-x-3">
        <div style={{ width: "500px" }}>
          <TimeCardComponent lag={false} label="Forecast Date" isDisabled={false}/>
        </div>
        <div className="flex-auto">
          <ClusterCard />
        </div>
      </div>
      {showPageLoader && <PageLoader />}
      <ForecastAnalysisFilter pageLoadingHandler={pageLoaderHandler} />
      <ForecastAnalysis />
      <BacktestingResults widthColumn="4%" isResultVisulizationPage={false} isExperimentationPage={false} />
      <ForecastDecomposition />
    </div>
  );
};
