import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationType } from "./foreCastBackTestingDataSlice";
import { PAGE_LIMIT } from "../utils/constant";

interface RangeType {
  start: number;
  end: number;
}

interface DisplayNamesType {
  [key: string]: string;
}

export interface DataType {
  totalShipQuantity: number;
  totalBaselineQuantity: number;
  totalTradePromotionsQuantity: number;
  totalCustomerPromotionsQuantity: number;
  totalHolidaysEventsQuantity: number;
  totalFixedElementsQuantity: number;
  totalSeasonalQuantity: number;
  totalTradePartnersQuantity: number;
  totalMacroEconomicsQuantity: number;
  totalMerchandisingQuantity: number;
  totalSalesIncentivesQuantity: number;
  totalAdvertisingQuantity: number;
  totalWeatherQuantity: number;
  totalPerfectStoreQuantity: number;
  totalDirectPointOfSalesQuantity: number;
  totalInnovationNPDQuantity: number;
  totalListPriceQuantity: number;
  totalDARateQuantity: number;
  totalMarketShareQuantity: number;
  totalInternalInventoryAvailabilityQuantity: number;
  totalExternalInventoryAvailabilityQuantity: number;
  totalRawMaterialInventoryQuantity: number;
  totalPurchaseOrdersQuantity: number;
  totalMobilityQuantity: number;
  timeFrameStartDate: string;
  weekPeriodNumber: string;
}

interface ForecastDecompositionType {
  chartData: {
    range: RangeType;
  };
  displayNames: DisplayNamesType;
  data: DataType[];
  loadingDecomposition: boolean;
}

export const initialPaginationState: PaginationType = {
  pageNumber: 1,
  pageLimit: PAGE_LIMIT,
  totalPages: 0,
  isDataShownClicked: false
};

const initialState: ForecastDecompositionType = {
  chartData: {
    range: {
      start: 0,
      end: 0,
    },
  },
  displayNames: {},
  data: [],
  loadingDecomposition: false,
};

export const forecastDecompositionSlice = createSlice({
  name: "forecastDecomposition",
  initialState,
  reducers: {
    setForecastData: (
      state,
      action: PayloadAction<ForecastDecompositionType>
    ) => {
      state.chartData = action?.payload.chartData;
      state.displayNames = action?.payload.displayNames;
      state.data = action?.payload.data;
      state.loadingDecomposition = action?.payload.loadingDecomposition;
    },
    setLoadingDecomposition: (state) => {
      state.loadingDecomposition = true;
    },
    resetLoadingDecomposition: (state) => {
      state.loadingDecomposition = false;
    },
    resetForecastDecomState: () => initialState,
  },
});

export const foreCastDecompositionPagination = createSlice({
  name: "foreCastDecompositionPagination",
  initialState: initialPaginationState,
  reducers: {
    updateBackDecompositionPageNumber: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
    },
    updateBackDecompositionPageLimit: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
    },
    updateBackDecompositionTotalPages: (
      state,
      action: PayloadAction<PaginationType>
    ) => {
      state.pageLimit = action?.payload.pageLimit;
      state.totalPages = action?.payload.totalPages;
      state.pageNumber = action?.payload.pageNumber;
      state.isDataShownClicked = action?.payload.isDataShownClicked;
    },
  },
});

export const { setForecastData, resetForecastDecomState, setLoadingDecomposition, resetLoadingDecomposition } =
  forecastDecompositionSlice.actions;

export const { updateBackDecompositionPageLimit, updateBackDecompositionPageNumber, updateBackDecompositionTotalPages } =
  foreCastDecompositionPagination.actions;

export default forecastDecompositionSlice.reducer;
