import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect } from "react";
import { getAccuracyColor, getBiasColor } from "../../utils/functions";
import RankDropdownForward from "../../components/BackTesting/ResultVisulizationBackTesting/RankDropDown";

const ComparisonResultExperimentation = () => {
  const expData = useSelector(
    (state: RootState) => state.resultVisualizationExperimentationData
  );
  const filter = useSelector(
    (state: RootState) => state.resultVisualizationFilter
  );
  useEffect(() => {}, [filter]);
  const productionData = expData?.productionData;
  const experimentData = expData?.experimentData;

  const renderOverallPercent = (percentData: any) => {
    if (!percentData || percentData.length === 0) return "";
    return Object.entries(percentData)
      .map(
        ([key, value]) =>
          `${(value as number).toFixed(1)}% ${
            key.charAt(0).toUpperCase() + key.slice(1)
          }`
      )
      .join(", ");
  };

  const isNoData =
    !productionData?.overallAverage ||
    !experimentData ||
    experimentData.length === 0 ||
    (productionData?.overallAverage?.accuracy === 0 &&
      productionData?.overallAverage?.bias === 0 &&
      productionData?.overallAverage?.historicalShipments === 0 &&
      productionData?.overallAverage?.statisticalForecast === 0 &&
      experimentData.every(
        (exp: any) =>
          exp.overallAverage.accuracy === 0 &&
          exp.overallAverage.bias === 0 &&
          exp.overallAverage.historicalShipments === 0 &&
          exp.overallAverage.statisticalForecast === 0
      ));

  return (
    <div className="mt-4">
      <div className="p-2 bg-secondary-bg rounded">
        <div className="flex mb-2">
          <p className="text-lg font-medium mb-4 mt-2 pt-2">
            Comparison Results
          </p>
          <RankDropdownForward rankType="rankComparision" />
        </div>

        {isNoData ? (
          <div className="flex justify-center">
            <p className="p-2 m-1">
              No Data to show or no options selected, please choose from the
              above filters.
            </p>
          </div>
        ) : (
          <div className="p-4 mt-2 bg-secondary-bg rounded">
            {/* Production Data Section */}
            <div className="mt-8">
              <p className="text-lg font-bold text-secondary-text">
                Production Model
              </p>
              <p className="text-base mt-2 text-secondary-text">
                {renderOverallPercent(productionData?.overallPercent?.[0])}
              </p>
              <p className="text-base text-secondary-text">
                {renderOverallPercent(productionData?.overallPercent?.[1])}
              </p>
            </div>
            {productionData?.overallPercent?.[2]?.length > 0 && (
              <div className="pt-4">
                <p className="text-lg font-bold text-secondary-text">
                  Experiment ID:
                  {renderOverallPercent(productionData?.overallPercent?.[2])}
                </p>
              </div>
            )}
            <div className="w-full grid grid-cols-4 gap-0 mt-5">
              <div
                className={`p-3 rounded-l-lg ${
                  getAccuracyColor(
                    productionData?.overallAverage?.accuracy || 0
                  ).bg
                } ${
                  getAccuracyColor(
                    productionData?.overallAverage?.accuracy || 0
                  ).text
                }`}
              >
                <p className="text-2xl font-bold">
                  {Math.trunc(productionData?.overallAverage?.accuracy || 0)}%
                </p>
                <p className="text-sm font-normal">Forecast Accuracy</p>
              </div>
              <div
                className={`p-3 border-l border-black ${
                  getBiasColor(productionData?.overallAverage?.bias).bg
                } ${getBiasColor(productionData?.overallAverage?.bias).text}`}
              >
                <p className="text-2xl font-bold">
                  {productionData?.overallAverage?.bias || 0}%
                </p>
                <p className="text-sm font-normal">Bias</p>
              </div>
              <div className="bg-ternary-bg p-3 border-l border-x-gray-700">
                <p className="text-2xl font-bold">
                  {Math.trunc(
                    productionData?.overallAverage?.historicalShipments || 0
                  ).toLocaleString()}
                </p>
                <p className="text-sm font-normal">Historical Shipments</p>
              </div>
              <div className="bg-ternary-bg p-3 rounded-r-lg border-l border-x-gray-700">
                <p className="text-2xl font-bold">
                  {Math.trunc(
                    productionData?.overallAverage?.statisticalForecast || 0
                  ).toLocaleString()}
                </p>
                <p className="text-sm font-normal">Statistical Forecast</p>
              </div>
            </div>

            {/* Experiment Data Section */}
            {experimentData?.map((experiment: any, index: number) => (
              <div key={experiment.rank} className="mt-8">
                <p className="text-lg font-bold text-secondary-text">
                  Rank {experiment.rank}
                </p>
                <p className="text-base mt-2 text-secondary-text">
                  {renderOverallPercent(experiment?.overallPercent?.[0])}
                </p>
                <p className="text-base text-secondary-text">
                  {renderOverallPercent(experiment?.overallPercent?.[1])}
                </p>
                <div className="w-full grid grid-cols-4 gap-0 mt-5">
                  <div
                    className={`p-3 rounded-l-lg ${
                      getAccuracyColor(
                        experiment?.overallAverage?.accuracy || 0
                      ).bg
                    } ${
                      getAccuracyColor(
                        experiment?.overallAverage?.accuracy || 0
                      ).text
                    }`}
                  >
                    <p className="text-2xl font-bold">
                      {Math.trunc(experiment?.overallAverage?.accuracy || 0)}%
                    </p>
                    <p className="text-sm font-normal">Forecast Accuracy</p>
                  </div>
                  <div
                    className={`p-3 border-l border-black ${
                      getBiasColor(experiment?.overallAverage?.bias || 0).bg
                    } ${
                      getBiasColor(experiment?.overallAverage?.bias || 0).text
                    }`}
                  >
                    <p className="text-2xl font-bold">
                      {experiment?.overallAverage?.bias.toFixed(1) || 0.0}%
                    </p>
                    <p className="text-sm font-normal">Bias</p>
                  </div>
                  <div className="bg-ternary-bg p-3 border-l border-x-gray-700">
                    <p className="text-2xl font-bold">
                      {Math.trunc(
                        experiment?.overallAverage?.historicalShipments || 0
                      ).toLocaleString()}
                    </p>
                    <p className="text-sm font-normal">Historical Shipments</p>
                  </div>
                  <div className="bg-ternary-bg p-3 rounded-r-lg border-l border-x-gray-700">
                    <p className="text-2xl font-bold">
                      {Math.trunc(
                        experiment?.overallAverage?.statisticalForecast || 0
                      ).toLocaleString()}
                    </p>
                    <p className="text-sm font-normal">Statistical Forecast</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ComparisonResultExperimentation;
