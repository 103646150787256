import { useSelector } from "react-redux";
import BasicTabs from "../../../components/Tabs/customTabPanel";
import Treemap from "../../../components/chart/Treemap/Treemap";
import KeyMap from "./KeyMap";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";

interface DataPoint {
  x: string;
  y: number;
  color: string;
}

export interface Series {
  data: DataPoint[];
}

const RcaSummaryCards = () => {
  const [chartData, setChartData] = useState<any>([]);

  let totalColors = [
    "#007EB5",
    "#66C4EE",
    "#6E3BB2",
    "#B992EB",
    "#006841",
    "#339B74",
  ];

  const rcaDataStatGraph = useSelector(
    (state: RootState) => state.rcaData.rcaTreeGraph
  );
  function transformData(rcaTreeGraph: any, predefinedColors: any) {
    const colors = predefinedColors || [
      "#007EB5",
      "#66C4EE",
      "#6E3BB2",
      "#B992EB",
      "#006841",
      "#339B74",
    ];

    const generateRandomColor = () =>
      `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")}`;

    return [
      {
        data: rcaTreeGraph?.map((item: any, index: any) => ({
          x: item.rcaStat,
          y: item.errorQuantity,
          color: colors[index % colors.length] || generateRandomColor(),
        })),
        group: "apexcharts-axis-0",
      },
    ];
  }

  useEffect(() => {
    if (rcaDataStatGraph?.length > 0) {
      setChartData(transformData(rcaDataStatGraph, totalColors));
    }
  }, [rcaDataStatGraph]);

  let statColors = [
    "#007EB5",
    "#66C4EE",
    "#6E3BB2",
    "#B992EB",
    "#006841",
    "#339B74",
    "#FFA800",
  ];

  return (
    <div className="bg-ternary-bg p-3 mt-4 rounded">
      <BasicTabs tabLabels={["RCA Stat"]} tabStyles={{ color: "white" }}>
        <div className="flex flex-row">
          <div className="basis-9/12">
            {chartData[0]?.data?.length > 0 ? (
              <Treemap colors={statColors} series={chartData} />
            ) : (
              <div className="flex justify-center">
                <p className="p-2 pl-60 m-1 ml-20">
                  No Data to show or no options selected, please choose from the
                  above filters.
                </p>
              </div>
            )}
          </div>
          {chartData[0]?.data?.length > 0 ? (
            <div className="basis-3/12">
              <KeyMap series={chartData} />
            </div>
          ) : null}
        </div>
        <div className="flex flex-row">
          <div className="basis-9/12">
            {/* <Treemap colors={totalColors} series={totalSeries} /> */}
          </div>
          <div className="basis-3/12">
            {/* <KeyMap series={totalSeries} /> */}
          </div>
        </div>
      </BasicTabs>
    </div>
  );
};

export default RcaSummaryCards;
