import { postAPI } from "../API";
import {
  DATA_SHOWN_CATEGORY_KEY_VALUE_MAPPING,
  DATA_SHOWN_CATEGORY_LIST_NAMES,
} from "../utils/constant";

export const forecastAnalysisTableApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "forecast/forecastanalysis/table",
    {
      ...apiRequestBody,
    },
    authState?.accessToken?.accessToken!
  )
    .then((res) => {
      return res?.data?.response;
    })
    .catch((error) => {
      console.log("Forecast BackTesting Table Api went wrong", error);
      return error;
    });
};

export const forecastAnalysisChartApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "forecast/forecastanalysis/chart",
    {
      ...apiRequestBody,
    },
    authState?.accessToken?.accessToken!
  )
    .then((res) => {
      return res?.data?.response;
    })
    .catch((error) => {
      console.log("Forecast BackTesting Chart Api went wrong", error);
      return error;
    });
};

export const forecastAnalysisHolidaysApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "forecast/forecastanalysis/holidays",
    {
      ...apiRequestBody,
    },
    authState?.accessToken?.accessToken!
  )
    .then((res) => {
      return res?.data?.response;
    })
    .catch((error) => {
      console.log("Forecast BackTesting Average Api went wrong", error);
      return error;
    });
};

export const forecastBackTestingTableApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "forecast/forecastbacktesting/table",
    {
      ...apiRequestBody,
    },
    authState?.accessToken?.accessToken!
  )
    .then((res) => {
      return res?.data?.response;
    })
    .catch((error) => {
      console.log("Forecast BackTesting Table Api went wrong", error);
      return error;
    });
};

export const forecastBackTestingChartApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "forecast/forecastbacktesting/chart",
    {
      ...apiRequestBody,
    },
    authState?.accessToken?.accessToken!
  )
    .then((res) => {
      return res?.data?.response;
    })
    .catch((error) => {
      console.log("Forecast BackTesting Chart Api went wrong", error);
      return error;
    });
};

export const forecastBackTestingAverageApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "forecast/forecastbacktesting/average",
    {
      ...apiRequestBody,
    },
    authState?.accessToken?.accessToken!
  )
    .then((res) => {
      return res?.data?.response;
    })
    .catch((error) => {
      console.log("Forecast BackTesting Average Api went wrong", error);
      return error;
    });
};

export const forecastDecompositionApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "forecast/forecastdecomposition",
    {
      ...apiRequestBody,
    },
    authState?.accessToken?.accessToken!
  )
    .then((res) => {
      return res?.data?.response;
    })
    .catch((error) => {
      console.log("Decomposition API Error", error);
    });
};

export const resultVisualisationAverageAPI = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "predefinedbacktesting/average",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const resultVisualisationTableAPI = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "predefinedbacktesting/table",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const resultVisualizationAverageExperimentaitonApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "experimentation/average",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const resultVisualisationFilterExperimentaionApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "experimentation/filters",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const resultVisualisationChartExperimentaionApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "experimentation/chart",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const resultVisualisationChartExperimentaionTableApi = async (
  apiRequestBody: any,
  authState: any
) => {
  return postAPI(
    "experimentation/table",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const resultVisualisationChartExperimentaionForwardForecastTableApi =
  async (apiRequestBody: any, authState: any) => {
    return postAPI(
      "ff/table",
      { ...apiRequestBody },
      authState?.accessToken?.accessToken!
    ).then((res) => {
      return res?.data?.response;
    });
  };

export const rcaMetricsApi = async (apiRequestBody: any, authState: any) => {
  return postAPI(
    "rca/metrics",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const rcaTreeGraphApi = async (apiRequestBody: any, authState: any) => {
  return postAPI(
    "rca/treegraph",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const rcaExecutionTableApi = async (apiRequestBody: any, authState: any) => {
  return postAPI(
    "rca/execution",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const rcaSummaryTableApi = async (apiRequestBody: any, authState: any) => {
  return postAPI(
    "rca/summary",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const rcaWriteBackApi = async (apiRequestBody: any, authState: any) => {
  return postAPI(
    "rca/writeback",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const forwardChartAPI = async (apiRequestBody: any, authState: any) => {
  return postAPI(
    "ff/chart",
    { ...apiRequestBody },
    authState?.accessToken?.accessToken!
  ).then((res) => {
    return res?.data?.response;
  });
};

export const forecastBackTestingDefaultGroupBy = [
  "Key Account",
  "Key Account Name",
  "Sales Region",
  "UPC",
  "Planning Item",
  "Cluster Id",
];

export const forecastAnalysisDefaultGroupBy = [
  "Key Account",
  "Key Account Name",
  "Sales Region",
  "UPC",
  "Planning Item",
  "Cluster Id",
];

export const resultVisulizationExperimentDefaultGroupBy = [
  "Cluster Id",
  "UPC",
  "Key Account",
  "Sales Region",
  "Planning Item",
  "Key Account Name",
];

export const resultVisulizationPredefineDefaultGroupBy = [
  "Cluster Id",
  "UPC",
  "Key Account",
  "Sales Region",
  "Planning Item",
  "Key Account Name",
];

/**
 * Mapping from the Array of GroupBy category to its value
 * @param selectedOptionsFields
 * @returns {selectedGroupByOptions, selectedCategoryType}
 */
export const mapperForGroupByLableToKey = (
  selectedOptionsFields: string[] = []
): {
  selectedGroupByOptions: string[];
  selectedCategoryType: string[];
} => {
  const { items, customer, salesGeo, pfe } =
    DATA_SHOWN_CATEGORY_KEY_VALUE_MAPPING;
  const {
    item,
    customer: customerName,
    salesGeo: salesGeoName,
    pfe: pfeName,
  } = DATA_SHOWN_CATEGORY_LIST_NAMES;

  const selectedGroupByOptions: string[] = [];
  const selectedCategoryType: string[] = [];

  const mapCategory = (
    keys: string[],
    values: string[],
    categoryName: string
  ) => {
    keys?.forEach((key, index) => {
      if (selectedOptionsFields.includes(key)) {
        selectedGroupByOptions.push(values[index]);
        if (!selectedCategoryType.includes(categoryName)) {
          selectedCategoryType.push(categoryName);
        }
      }
    });
  };

  if (selectedOptionsFields.length > 0) {
    mapCategory(Object.keys(pfe), Object.values(pfe), pfeName);
    mapCategory(Object.keys(items), Object.values(items), item);
    mapCategory(Object.keys(customer), Object.values(customer), customerName);
    mapCategory(Object.keys(salesGeo), Object.values(salesGeo), salesGeoName);
  }

  return {
    selectedGroupByOptions,
    selectedCategoryType,
  };
};

export const calculateTotalPages = (
  pageLimit: number,
  totalPageRows: number
): number => {
  const totalPages = Math.ceil(totalPageRows / pageLimit);

  return totalPages;
};
